import React from 'react';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import NoteIcon from '@mui/icons-material/Note';
import TablePagination from '@mui/material/TablePagination';
import BackupTable from '@mui/icons-material/BackupTable';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';

import { AppState } from '../states/appState';
import { RepealState } from '../states/repealState';
import { SearchState } from '../states/searchState';
import { RepealActions } from '../containers/repealContainer';
import { Hit, Conditions, EditingGroup } from '../states/searchState';
import { RevisionDialog } from './RevisionDialog'

type RepealProps = {} & AppState & RepealState & RepealActions & SearchState;

export const Repeal: React.FC<RepealProps> = (props) => {

  const actView = (actId, revision) => {
    const query = `?type=${'revision'}&actId=${actId}&miekesi=false`;
    const url = process.env.REACT_APP_VIEWER_URL ? process.env.REACT_APP_VIEWER_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_VIEWER_URL;
    window.open(url + query, `viewer_${actId}_${revision}`);
  };

  const pageChange = (event, page) => {
    const c: Conditions = JSON.parse(JSON.stringify(props.rconditions));
    c.page = page;
    props.setLoading(true);
    props.searchRepealAsync(c, props.accountid);
  };

  const defaultLabelDisplayedRows = ({ from, to, count }) => {
    return `${count !== -1 ? count : `more than ${to}`}件中 ${from}–${to}件目`;
  };

  // リビジョン操作ダイアログ表示
  const [anchorElRevisionDialog, setAnchorElRevisionDialog] = React.useState<null | HTMLElement>(null);
  const openRevisionDialog = Boolean(anchorElRevisionDialog);
  const handleRevisionDialog = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRevisionDialog(event.currentTarget);
  };
  const handleCloseRevisionDialog = () => {
    props.clearEnforcegroups('');
    setAnchorElRevisionDialog(null);
  };
  const handleCommitRevisionDialog = (editingGroups: EditingGroup[]) => {
    // commit
    props.setLoading(true);
    //props.postEnforcegroups(props.accountid, props.id, props.idid, props.editingGroups[0].enforceGroups[0].actId, editType, editingGroups);
    const targetEId = editingGroups[editingGroups.length - 1].editingId;
    props.postEnforceCancelAsync(props.accountid, props.id, props.idid, props.editingGroups[0].enforceGroups[0].actId, [targetEId]);
    handleCloseRevisionDialog();
  };

  const revision = (actid: string) => (event: React.MouseEvent<HTMLElement>) => {
    props.setLoading(true);
    props.getEnforcegroupsAsync(actid, props.accountid);

    handleRevisionDialog(event);
  };

  const createList = () => {
    const createAction = (r: Hit) => {
      return r.fields.historyicondisp[0] && r.fields.eventtype[0] === '3' ? (
        <ListItemSecondaryAction>
          <Tooltip title="改正履歴">
            <IconButton sx={{
              color: (theme) => theme.palette.secondary.main,
              margin: 1,
            }} edge="end" aria-label="revision" onClick={revision(r.fields.actid[0])}>
              <ManageHistoryIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      ) : '';
    };

    return props.rsearchResultSet ? props.rsearchResultSet.hit.map((i, index) => {
      const v = i.fields;
      return (
        <div key={"" + index}>
          <ListItem button onClick={() => actView(v.actid, v.revision)}>
            <ListItemIcon sx={{
              color: (theme) => theme.palette.primary.main,
            }}>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary={v.title} secondary={
              <>
                <Typography variant="subtitle1" component="span">番号：</Typography>
                <Typography variant="body1" component="span">{v.actnum}</Typography>
                <br />
              </>
            } />
            {createAction(i)}
          </ListItem>
          <Divider />
        </div>
      )
    }) : '';
  };

  return (
    <>
      <Typography sx={{ margin: 2 }} variant="h5" gutterBottom>
        <BackupTable fontSize='large' color='primary' sx={{ marginBottom: 1, marginRight: 1, verticalAlign: 'middle' }} />
        廃止・失効された文書
      </Typography>
      <Paper sx={{
        minWidth: 275,
        margin: 2,
        padding: 1,
        backgroundColor: (theme) => theme.palette.primary[50],
      }} elevation={3}>
        <List component="nav" aria-label="My editing acts">
          {createList()}
        </List>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={props.rsearchResultSet ? props.rsearchResultSet.found : 0}
        rowsPerPage={props.rconditions.rowsPerPage}
        page={props.rconditions.page}
        onPageChange={pageChange}
        labelDisplayedRows={defaultLabelDisplayedRows}
      /><br /><br /><br />
      <RevisionDialog
        open={openRevisionDialog}
        accountid={props.accountid}
        role={props.role}
        editingGroups={props.editingGroups}
        editingGroupsOrg={props.editingGroupsOrg}
        getEnforcegroups={props.getEnforcegroups}
        handleCommit={handleCommitRevisionDialog}
        handleClose={handleCloseRevisionDialog}
      />
    </>
  );
};