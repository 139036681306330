import { useTheme, View, Text, Heading } from '@aws-amplify/ui-react'

const AuthenticatorComponent = {
    Header() {
        const { tokens } = useTheme()
        return <View padding={tokens.space.xxxl} />
    },
    Footer() {
        const { tokens } = useTheme()
        return (
            <View textAlign="center" padding={tokens.space.xxxl}>
                <Text color={`${tokens.colors.neutral['80']}`}>
                    {/* &copy; All Rights Reserved */}
                </Text>
            </View>
        )
    },
    SignIn: {
        Header() {
            return <Heading>サインイン</Heading>
        },
    },
    ConfirmSignIn: {
        Header() {
            return <Heading>サインイン</Heading>
        },
    },
    ResetPassword: {
        Header() {
            return <Heading>パスワードを忘れた場合</Heading>
        },
    },
    ConfirmResetPassword: {
        Header() {
            return <Heading>パスワードのリセット</Heading>
        },
    },
}

export default AuthenticatorComponent