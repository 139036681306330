import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { informationActions } from '../actions/informationAction';

export interface InformationState {
    informations: InformationItem[];
    accounts: Account[];
};

export interface InformationItem {
    accountId: string;
    id: string; // userid
    informationId: string;
    account: string;
    startDate: string;
    endDate: string;
    isUnlimited: boolean;
    from: string;
    type: number;
    priority: boolean;
    role: number;
    title: string;
    text: string;
    attachment: string[];
    userName: string;
};

export interface Account {
    accountId: string;
    govName: string;
    govNameSuffix: string;
    prefsName: string;
    invalid: boolean;
};

const initialState: InformationState = {
    informations: [],
    accounts: [],
};

export const informationReducer = reducerWithInitialState(initialState)
    .case(informationActions.getInformations, (state, payload) => {
        return Object.assign({}, state, { informations: payload });
    }).case(informationActions.getAccounts, (state, payload) => {
        return Object.assign({}, state, { accounts: payload })
    });
