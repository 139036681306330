import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import ReplayIcon from '@mui/icons-material/Replay';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Link from '@mui/material/Link';
import WarningIcon from '@mui/icons-material/Warning';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

import { EditingGroup, EnforceGroup } from '../states/searchState';
import { ReEnforceDateDialog } from './ReEnforceDateDialog';

type Props = {
  open: boolean;
  editingGroups: EditingGroup[];
  editingGroupsOrg: EditingGroup[];
  getEnforcegroups: (any) => void;
  handleCommit: (editingGroups: EditingGroup[], editType: string) => void;
  handleClose: () => void;
};

const s_EDIT_TYPE = {
  0: '新規制定',
  1: '一部改正',
  2: '全部改正',
  3: '廃止',
  10: '全部改正廃止',
  11: '失効',
};

// 編集個別表示OPEN
const editingView = (editingId: string, actId: string, stepId: string, editType: number) => {
  const query = `?type=${'editor'}&actId=${actId}&editingId=${editingId}&stepId=${stepId}&editType=${editType}&miekesi=true`;
  const url = process.env.REACT_APP_VIEWER_URL ? process.env.REACT_APP_VIEWER_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_VIEWER_URL;
  window.open(url + query, `viewer_${actId}_${editingId}_${stepId}`);
};

// Revision個別表示OPEN
const actView = (actId, revision) => {
  const query = `?type=${'revision'}&actId=${actId}&revision=${revision}&miekesi=false`;
  const url = process.env.REACT_APP_VIEWER_URL ? process.env.REACT_APP_VIEWER_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_VIEWER_URL;
  window.open(url + query, `viewer_${actId}_${revision}`);
};

export const RealEnforceDateDialog: React.FC<Props> = (props) => {

  const [anchorRevisionLock, setAnchorRevisionLock] = React.useState<null | HTMLElement>(null);
  const revisionLock = Boolean(anchorRevisionLock);
  const handleStartRevisionLock = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorRevisionLock(event.currentTarget);
  };
  const handleCommitRevisionLock = () => {
    // 変更がなければコミットさせない
    if (props.editingGroups.find((eg) => eg.delete || eg.enforceGroups.find((eng) => eng.changed === true)) !== undefined) {
      props.handleCommit(props.editingGroups, '81');
      setAnchorRevisionLock(null);
    } else {
      setSubDialog(true);
    }
  };
  const handleCancelRevisionLock = () => {
    const news = JSON.parse(JSON.stringify(props.editingGroupsOrg));
    props.getEnforcegroups({ editingGroups: news });
    setAnchorRevisionLock(null);
  };

  const [subDialog, setSubDialog] = React.useState(false);
  const openSubDialog = Boolean(subDialog);
  const closeSubDialog = () => setSubDialog(false);

  // 施行日設定
  const [openEnforceDate, setOpenEnforceDate] = React.useState(false);
  const handleEnforceDateClose = () => {
    setOpenEnforceDate(false);
  };
  const [targetEditingGroup, setTargetEditingGroup] = React.useState<null | EditingGroup>(null);
  const handleChangeTargetEditingGroup = (eg: EditingGroup) => {
    const newEg: EditingGroup = JSON.parse(JSON.stringify(eg));
    setTargetEditingGroup(newEg);
  };
  const handleChangeEditingGroup = () => {
    const newEg: EditingGroup = JSON.parse(JSON.stringify(targetEditingGroup));
    const news: EditingGroup[] = JSON.parse(JSON.stringify(props.editingGroupsOrg));
    const news2 = news.map((e) => {
      if (e.editingId === newEg.editingId) {
        return newEg;
      }
      return e;
    });
    props.getEnforcegroups({ editingGroups: news2 });
  };

  const viewLockButton = () => {
    return revisionLock ? (
      <>
        <Button autoFocus color="inherit" size="large" onClick={handleCommitRevisionLock}>
          編集内容を確定する
        </Button>
        <Button autoFocus color="inherit" size="large" onClick={handleCancelRevisionLock}>
          キャンセル
        </Button>
      </>
    ) : (
      <Button autoFocus color="inherit" size="large" onClick={handleStartRevisionLock}>
        編集する
      </Button>
    );
  }

  const handleChangeEnforceDate = (eg: EditingGroup) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const newEg: EditingGroup = JSON.parse(JSON.stringify(eg));
    setTargetEditingGroup(newEg);
    setOpenEnforceDate(true);
  };

  const handleUnDeleteEditing = (editingId) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    const news: EditingGroup[] = JSON.parse(JSON.stringify(props.editingGroups));
    const newnews = news.map((e) => {
      if (e.editingId === editingId) {
        delete e.delete;
        const neweg = e.enforceGroups.map((eg) => {
          if (eg.enforceDateOrg) {
            eg.enforceDate = eg.enforceDateOrg;
            delete eg.enforceDateOrg;
          }
          if (eg.informalOrg) {
            eg.informal = eg.informalOrg;
            delete eg.informalOrg;
          }
          return eg;
        });
        e.enforceGroups = neweg;
      }
      return e;
    });
    props.getEnforcegroups({ editingGroups: newnews });
  };

  const ediitngView = (editingId: string, actId: string, stepId: string, editType: number) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    editingView(editingId, actId, stepId, editType);
  };

  const revisionView = (actId, revision) => (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    actView(actId, revision);
  };

  const getDateString = (s) => {
    const r = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', {
      year: 'numeric',
      month: 'narrow',
      day: 'numeric',
      era: 'long'
    }).format(new Date(s));
    return r;
  }

  const getEnforceDate = (s, length: number, index: number) => {
    const d = getDateString(s);
    const t = length === 1 ? '施行' : index === 0 ? '公布' : '施行';
    const r = `${d}　${t}`;
    return r;
  };

  const getInformal = (informal) => {
    return informal ? (
      <Tooltip title="仮施行日が設定されています">
        <WarningIcon sx={{
          marginLeft: (theme) => theme.spacing(2),
          color: "#ffb74d",
        }} />
      </Tooltip>
    ) : '';
  };

  const createAccordionDetails = (egs: EnforceGroup[]) => {
    return egs.map((e, i) => {
      return (
        <span key={i}>
          <Link
            component="button"
            variant="body1"
            onClick={revisionView(e.actId, e.revision)}
          >
            {getEnforceDate(e.enforceDate, egs.length, i)}
            {getInformal(e.informal)}
          </Link>
          <br />
        </span>
      );
    });
  };

  const viewEnforceDateButton = (eg: EditingGroup) => {
    return revisionLock && eg.enforceGroups.find((e) => e.informal || (!e.informal && e.informalOrg)) ? (
      <Tooltip title="本施行日を設定する">
        <IconButton sx={{
          color: (theme) => theme.palette.secondary.main,
          margin: 1,
        }} edge="end" aria-label="date" onClick={handleChangeEnforceDate(eg)} >
          <CalendarTodayIcon />
        </IconButton >
      </Tooltip >
    ) : '';
  };

  const viewReButton = (editingId) => {
    return revisionLock ? (
      <Tooltip title="変更を取り消す">
        <IconButton sx={{
          color: (theme) => theme.palette.secondary.main,
          margin: 1,
        }} edge="end" aria-label="delete" onClick={handleUnDeleteEditing(editingId)} >
          <ReplayIcon />
        </IconButton >
      </Tooltip >
    ) : '';
  };

  const createAccordions = () => {
    return (props.editingGroups && props.editingGroups.length > 0) ? props.editingGroups.map((e, i) => {
      if (e.delete || e.enforceGroups.find((e2) => e2.informalOrg || e2.enforceDateOrg)) {
        return (
          <Accordion key={i} defaultExpanded={true} sx={{
            backgroundColor: (theme) => theme.palette.secondary[50],
          }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography sx={{
                fontSize: (theme) => theme.typography.pxToRem(15),
              }}>
                <Link
                  component="button"
                  variant="h6"
                  onClick={ediitngView(e.editingId, e.enforceGroups[0].actId, '1', e.enforceGroups[0].editType)}
                >
                  {`${e.enforceGroups[0].editActNum}（${s_EDIT_TYPE[e.enforceGroups[0].editType]}）`}
                </Link>
                {viewEnforceDateButton(e)}
                {viewReButton(e.editingId)}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {createAccordionDetails(e.enforceGroups)}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      }
      return (
        <Accordion key={i} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography sx={{
              fontSize: (theme) => theme.typography.pxToRem(15),
            }}>
              <Link
                component="button"
                variant="h6"
                onClick={ediitngView(e.editingId, e.enforceGroups[0].actId, '1', e.enforceGroups[0].editType)}
              >
                {`${e.enforceGroups[0].editActNum}（${s_EDIT_TYPE[e.enforceGroups[0].editType]}）`}
              </Link>
              {viewEnforceDateButton(e)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {createAccordionDetails(e.enforceGroups)}
            </Typography>
          </AccordionDetails>
        </Accordion>
      );
    }) : '';
  };

  const onDialogClose = () => {
    handleCancelRevisionLock();
    props.handleClose();
  };

  return (
    <Dialog
      fullScreen
      open={props.open}
      keepMounted
      onClose={onDialogClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <header>
        <Box sx={{
          flexGrow: 1,
          height: (theme) => theme.spacing(8),
        }}>
          <AppBar position="fixed">
            <Toolbar>
              <Tooltip title="閉じる">
                <IconButton edge="start" color="inherit" onClick={onDialogClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" sx={{
                marginLeft: (theme) => theme.spacing(2),
                flex: 1,
              }}>
                本施行日を設定する
              </Typography>
              {viewLockButton()}
            </Toolbar>
          </AppBar>
        </Box>
      </header>
      <Paper sx={{
        width: '98%',
        margin: (theme) => theme.spacing(2),
        overflowX: 'auto',
      }}>
        {createAccordions()}
      </Paper>
      <ReEnforceDateDialog
        open={openEnforceDate}
        editingGroup={targetEditingGroup}
        hadleChangeTargetDate={handleChangeTargetEditingGroup}
        hadleChangeDate={handleChangeEditingGroup}
        handleClose={handleEnforceDateClose}
      />
      <Dialog
        open={openSubDialog}
        keepMounted
        onClose={closeSubDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Warning</DialogTitle>
        <DialogContent>
          <Typography>変更がありません。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSubDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};  