import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { editingActsAction } from '../actions/editingActsAction';

export interface EditingActTablesState {
    editingActTables: EditingActState[];
};

export interface EditingActState {
    editingId: string;
    actId: string;
    title: string;
    newTitle: string;
    editType: string;
    actNum: string;
    editActNum: string;
    editState: string;
    stepId: string;
};

const initialState: EditingActTablesState = {
    editingActTables: [{
        editingId: '',
        actId: '',
        title: '',
        newTitle: '',
        editType: '99',
        actNum: '',
        editActNum: '',
        editState: '99',
        stepId: '1',
    }],
};

export const editingActReducer = reducerWithInitialState(initialState)
    .case(editingActsAction.getEditingActTables, (state, payload) => {
        const result = payload.map(item => {
            return {
                editingId: item.editingId,
                actId: item.actId,
                title: item.title,
                newTitle: item.newTitle,
                editType: item.editType,
                actNum: item.actNum,
                editActNum: item.editActNum,
                editState: item.editState,
                stepId:item.stepId,
            };
        });
        return Object.assign({}, state, { editingActTables: result });
    });
