import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@mui/material/Checkbox';

import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';

import Tooltip from '@mui/material/Tooltip';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

import { ActInfo, EditActInfo, EnforceInfo } from '../states/approvalState';

type Props = {
  open: boolean;
  enforceInfo: EnforceInfo;
  editType: string;
  actInfo: ActInfo;
  editActInfo: EditActInfo;
  setStateEnforceInfo: (i: EnforceInfo) => void;
  handleClose: () => void;
  handleOk: () => void;
};

export const EnforceDateDialog: React.FC<Props> = (props) => {

  const handleDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newInfo: EnforceInfo = JSON.parse(JSON.stringify(props.enforceInfo));
    const index = Number.parseInt((event.target as HTMLInputElement).id) - 1;
    newInfo.enforceGroups[index].enforceDate = (event.target as HTMLInputElement).value;
    props.setStateEnforceInfo(newInfo);
  };

  const handleMappingChange = (event: SelectChangeEvent) => {
    const v = (event.target as HTMLInputElement).value;
    const [egid, cgid, stepId] = v.split('#');
    const newInfo: EnforceInfo = JSON.parse(JSON.stringify(props.enforceInfo));
    const index = newInfo.changeGroups.findIndex((v) => v.changeGroupId === `${cgid}#${stepId}`);
    newInfo.changeGroups[index].enforceGroupId = egid;
    props.setStateEnforceInfo(newInfo);
  };

  const handleInformalChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const index = ((event.target as HTMLInputElement).name).split('#')[1];
    const newInfo: EnforceInfo = JSON.parse(JSON.stringify(props.enforceInfo));
    newInfo.enforceGroups[index].informal = (event.target as HTMLInputElement).checked;
    props.setStateEnforceInfo(newInfo);
  };

  const handleAddClick = () => {
    const newInfo: EnforceInfo = JSON.parse(JSON.stringify(props.enforceInfo));
    newInfo.enforceGroups.push({ enforceGroupId: '' + (newInfo.enforceGroups.length + 1), enforceDate: '', informal: false });
    props.setStateEnforceInfo(newInfo);
  };

  const [subDialog, setSubDialog] = React.useState(false);
  const openSubDialog = Boolean(subDialog);
  const closeSubDialog = () => setSubDialog(false);

  const handlePreOk = () => {
    const pDate = (props.editType === '0' || props.editType === '2') ? props.actInfo.publicationDate : props.editActInfo.publicationDate;
    if (pDate) {
      const sPDate = new Date(pDate);
      const fEG = props.enforceInfo.enforceGroups.find((e) => e.enforceDate && sPDate.getTime() > new Date(e.enforceDate).getTime());
      if (!fEG) {
        props.handleOk();
        return;
      }
    }
    setSubDialog(true);
  }

  const handleSetAll = (eid, date) => {
    if (date) {
      const newInfo: EnforceInfo = JSON.parse(JSON.stringify(props.enforceInfo));
      const newCg = newInfo.changeGroups.map(c => {
        c.enforceGroupId = eid;
        return c;
      });
      newInfo.changeGroups = newCg;
      props.setStateEnforceInfo(newInfo);
    }
  };

  const createEnforceDate = () => {
    return props.enforceInfo.enforceGroups.map((item, index) => {
      return (
        <div key={'div#' + index}>
          <TextField
            id={item.enforceGroupId}
            key={item.enforceGroupId + index}
            label={"施行日 " + item.enforceGroupId}
            type="date"
            value={item.enforceDate}
            onChange={handleDateChange}
            sx={{
              margin: (theme) => theme.spacing(1),
              width: 150,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <FormControlLabel
            sx={{
              margin: (theme) => theme.spacing(1),
            }}
            control={<Checkbox checked={item.informal} onChange={handleInformalChange}
              name={'informal#' + index} />}
            label="仮"
          />
          <Tooltip title="一括で適用する">
            <Fab size="small" color="primary" aria-label="add" sx={{
              margin: (theme) => theme.spacing(1),
            }} onClick={() => handleSetAll(item.enforceGroupId, item.enforceDate)}>
              <KeyboardDoubleArrowRightIcon />
            </Fab>
          </Tooltip>
        </div>
      );
    });
  };

  const createStepInfo = (enforceInfo: EnforceInfo, stepId: number) => {
    if (enforceInfo.editType === 1) {
      return (<Typography variant="body1">{`(${stepId}段目)`}</Typography>);
    }
  };

  const createListItem = () => {
    return props.enforceInfo.changeGroups.map((item, indexA) => {
      return (
        <ListItem key={item.changeGroupId + '#' + indexA} divider>
          <ListItemIcon sx={{
            color: (theme) => theme.palette.primary.main,
          }}>
            <ArrowRightIcon />
          </ListItemIcon>
          <ListItemText primary={
            <>
              {createStepInfo(props.enforceInfo, item.stepId)}
              {item.revisionSentenceArray.map((revisionSentence, index) => {
                return (
                  <Typography variant="body1" key={index}>{revisionSentence}</Typography>
                );
              })}
              <FormControl sx={{
                margin: (theme) => theme.spacing(1),
                minWidth: 120,
              }}>
                <InputLabel id="simple-select-label">施行日</InputLabel>
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  label="施行日"
                  value={item.enforceGroupId === '-1' ? '' : item.enforceGroupId + "#" + item.changeGroupId}
                  onChange={handleMappingChange}
                >
                  {props.enforceInfo.enforceGroups.map((eg, indexB) => {
                    return (
                      <MenuItem
                        id={eg.enforceGroupId}
                        key={eg.enforceGroupId + indexA + indexB}
                        value={eg.enforceGroupId + "#" + item.changeGroupId}
                      >
                        {eg.enforceGroupId}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </>
          } />
        </ListItem>
      );
    });
  };

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"施行日設定"}</DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: (theme) => theme.spacing(1),
        },
      }}>
        <Paper sx={{
          minWidth: 240,
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(1),
        }} elevation={3}>
          {createEnforceDate()}
          <Tooltip title="施行日を追加する">
            <Fab size="small" color="primary" aria-label="add" sx={{
              margin: (theme) => theme.spacing(1),
            }} onClick={handleAddClick}>
              <AddIcon />
            </Fab>
          </Tooltip>
        </Paper>
        <Paper sx={{
          minWidth: 360,
          width: '40%',
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(1),
        }} elevation={3}>
          <List component="nav" aria-label="change list.">
            {createListItem()}
          </List>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePreOk} color="primary">
          OK
        </Button>
        <Button onClick={props.handleClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
      <Dialog
        open={openSubDialog}
        keepMounted
        onClose={closeSubDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">施行日が正しくありません。</DialogTitle>
        <DialogContent>
          <Typography>施行日は公布日以降で入力してください。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSubDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

