import { connect } from 'react-redux';
import { Action } from 'typescript-fsa';

import { Repeal } from '../components/Repeal';
import { appActions } from '../actions/appAction';
import { repealActions } from '../actions/repealAction';
import { Conditions } from '../states/searchState';
import { searchActions } from '../actions/searchAction';

export interface RepealActions {
    search: (v: Conditions) => Action<Conditions>;
    searchRepealAsync: (c: Conditions, accountid: string) => void;
    getEnforcegroupsAsync: (actid: string, accountid: string) => void;
    getEnforcegroups: (any) => void;
    clearEnforcegroups: (any) => void;
    postEnforceCancelAsync: (accountid: string, id: string, idid: string, actId: string, editingIds: string[]) => void;
    setLoading: (loading: boolean) => void;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        search: (v: Conditions) => dispatch(repealActions.searchRepealCondition(v)),
        searchRepealAsync: (c: Conditions, accountid: string) => dispatch(repealActions.searchRepealAsync(c, accountid)),
        getEnforcegroupsAsync: (actid: string, accountid: string) => dispatch(searchActions.getEnforcegroupsAsync(actid, accountid)),
        getEnforcegroups: (any) => dispatch(searchActions.getEnforcegroups(any)),
        clearEnforcegroups: (any) => dispatch(searchActions.clearEnforcegroups(any)),
        postEnforceCancelAsync: (accountid: string, id: string, idid: string, actId: string, editingIds: string[]) =>
            dispatch(searchActions.postEnforceCancelAsync(accountid, id, idid, actId, editingIds)),
        setLoading: (loading: boolean) => dispatch(appActions.setLoading(loading)),
    };
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign({}, state.repealActsState, state.searchingState, state.appState);
};

export default connect(mapStateToProps, mapDispatchToProps)(Repeal);
