import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import { RevisionInfo } from './Search';

type Props = {
  open: boolean;
  value: RevisionInfo;
  list: RevisionInfo[];
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClose: () => void;
  okDialog: () => void;
};

export const SelectRevisionDialog: React.FC<Props> = (props) => {

  const createRevisionRadio = (p: RevisionInfo[]) => {
    const l = p.map((v, index) => {
      const type = v.revision === 0 ? '初期データ' : v.editType ? v.editType === 0 ? '新規制定' : v.editType === 1 ? '一部改正' : v.editType === 2 ? '全部改正' :
        v.editType === 3 ? '廃止' : v.editType === 10 ? '全部改正（廃止）' : v.editType === 11 ? '失効' : 'その他' : 'その他';
      const label = (v.editActNum ? v.editActNum + ' ' : '') + '履歴作成日時:' + v.revisionDate + ' 種別:' + type;
      return (<FormControlLabel key={v.revision} value={v.revision} control={<Radio />} label={label} />)
    });
    return (<>{l}</>);
  }

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"対象の履歴を選択してください"}</DialogTitle>
      <DialogContent>
        <RadioGroup aria-label="gender" name="gender1" value={props.value?.revision} onChange={props.handleRadioChange}>
          {props.list ? createRevisionRadio(props.list) : 'null'}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.okDialog} color="primary">
          Ok
        </Button>
        <Button onClick={props.handleClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
