import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Paper from '@mui/material/Paper';

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';

import { InformationItem, Account } from '../states/informationState';

type Props = {
  open: boolean;
  modeEditInformation: 'ADD' | 'EDIT';
  role: string;
  additionalItem: InformationItem;
  accounts: Account[];
  updateItem: (item: InformationItem) => void;
  handleClose: () => void;
  handleOk: () => void;
};

export const InformationAddDialog: React.FC<Props> = (props) => {

  const handleOrignOk = () => {
    checkOk();
  }

  const checkOk = () => {
    if (props.additionalItem.startDate.length < 1) {
      setSubMessage('掲載開始日は必須です。');
      setSubDialog(true);
    } else if (props.additionalItem.endDate.length < 1 && !props.additionalItem.isUnlimited)  {
      setSubMessage('掲載終了日を省略する場合は「無期限で表示する」にチェックを入れてください。');
      setSubDialog(true);
    } else {
      props.handleOk();
    }
  };

  const [subDialog, setSubDialog] = React.useState(false);
  const openSubDialog = Boolean(subDialog);
  const closeSubDialog = () => setSubDialog(false);
  const [subMessage, setSubMessage] = React.useState<string>('');

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const newValue = value.replace(/[\s]/g, '');
    const newnewValue = newValue ? newValue : '';
    const news: InformationItem = JSON.parse(JSON.stringify(props.additionalItem));
    news.title = newnewValue;
    props.updateItem(news);
  };

  const handleTxtChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const news: InformationItem = JSON.parse(JSON.stringify(props.additionalItem));
    news.text = (event.target as HTMLInputElement).value;
    props.updateItem(news);
  };

  const handleChangeAccount = (event) => {
    const news: InformationItem = JSON.parse(JSON.stringify(props.additionalItem));
    news.account = event.target.value;
    props.updateItem(news);
  };

  const handleChangeType = (event) => {
    const news: InformationItem = JSON.parse(JSON.stringify(props.additionalItem));
    news.type = event.target.value;
    props.updateItem(news);
  };

  const handleChangePriority = (event) => {
    const news: InformationItem = JSON.parse(JSON.stringify(props.additionalItem));
    news.priority = event.target.checked;
    props.updateItem(news);
  };

  const handleSDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const news: InformationItem = JSON.parse(JSON.stringify(props.additionalItem));
    news.startDate = (event.target as HTMLInputElement).value;
    props.updateItem(news);
  };

  const handleEDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const news: InformationItem = JSON.parse(JSON.stringify(props.additionalItem));
    news.endDate = (event.target as HTMLInputElement).value;
    props.updateItem(news);
  };
  const [disabledEDate, setDisabledEDateValue] = React.useState<boolean>(false);

  const handleChangeUnlimited = (event) => {
    setDisabledEDateValue(event.target.checked);

    const news: InformationItem = JSON.parse(JSON.stringify(props.additionalItem));
    news.endDate = '';
    news.isUnlimited = event.target.checked;
    props.updateItem(news);
  };

  const handleChangeRole = (event) => {
    const news: InformationItem = JSON.parse(JSON.stringify(props.additionalItem));
    news.role = event.target.value;
    props.updateItem(news);
  };

  const createAccountItem = () => {
    const createItems = () => {
      return props.accounts.map((a) => (
        <MenuItem key={a.accountId} value={a.accountId}>{a.govName}</MenuItem>
      ));
    }

    // 更新の場合、対象団体は操作できない
    // Managerのみに表示
    return props.role === 'Manager' ? (
      <>
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="account-select-readonly-label">対象団体</InputLabel>
          <Select
            labelId="account-select-helper-label"
            id="account-select-helper"
            label="対象団体"
            value={props.additionalItem.account}
            onChange={handleChangeAccount}
            disabled={props.modeEditInformation === 'EDIT'}
          >
            <MenuItem key={'All'} value={'All'}>{'全ての団体'}</MenuItem>
            {createItems()}
          </Select>
        </FormControl>
        <br />
      </>
    ) : '';
  };

  const createContents = () => {
    return (
      <>
        {createAccountItem()}
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="type-select-readonly-label">お知らせの種類</InputLabel>
          <Select
            labelId="type-select-helper-label"
            id="type-select-helper"
            label="お知らせの種類"
            value={props.additionalItem.type}
            onChange={handleChangeType}
          >
            <MenuItem key={'0'} value={0} ><InfoIcon color={'primary'} sx={{
              marginRight: 1,
            }} />{'情報'}</MenuItem>
            <MenuItem key={'1'} value={1} ><WarningIcon sx={{
              marginRight: 1,
              color: "#ffb74d",
            }} />{'注意'}</MenuItem>
            <MenuItem key={'2'} value={2} ><LabelImportantIcon sx={{
              marginRight: 1,
              color: "#ff4500",
            }} />{'重要'}</MenuItem>
          </Select>
        </FormControl>
        <br />
        <FormControlLabel
          control={
            <Checkbox
              checked={props.additionalItem.priority}
              onChange={handleChangePriority}
              name="priority"
              color="primary"
            />
          }
          label="優先して表示する"
        />
        <br />
        <TextField
          id={'sdate'}
          label={"掲載開始日"}
          type="date"
          value={props.additionalItem.startDate}
          onChange={handleSDateChange}
          sx={{
            margin: (theme) => theme.spacing(1),
            width: 150,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          id={'edate'}
          label={"掲載終了日"}
          type="date"
          disabled={disabledEDate}
          value={props.additionalItem.endDate}
          onChange={handleEDateChange}
          sx={{
            margin: (theme) => theme.spacing(1),
            width: 150,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={props.additionalItem.isUnlimited}
              onChange={handleChangeUnlimited}
              name="isUnlimited"
              color="primary"
            />
          }
          label="無期限で表示する"
        />
        <br />
        <FormControl sx={{ m: 1, minWidth: 200 }}>
          <InputLabel id="role-select-readonly-label">表示権限</InputLabel>
          <Select
            labelId="role-select-helper-label"
            id="role-select-helper"
            label="表示権限"
            value={props.additionalItem.role}
            onChange={handleChangeRole}
          >
            <MenuItem key={'0'} value={0}>{'全てのユーザに公開'}</MenuItem>
            <MenuItem key={'1'} value={1}>{'編集ユーザ以上に公開'}</MenuItem>
            <MenuItem key={'2'} value={2}>{'承認ユーザ以上に公開'}</MenuItem>
          </Select>
        </FormControl>
        <br />
        <TextField
          id={'title'}
          label={'タイトル'}
          value={props.additionalItem.title}
          sx={{
            width: 640,
          }}
          onChange={handleChangeTitle}
          margin="normal"
          variant="outlined"
        />
        <br />
        <TextField
          id={'text'}
          label={'本文'}
          multiline
          value={props.additionalItem.text}
          sx={{
            width: 640,
          }}
          rows={10}
          margin="normal"
          onChange={handleTxtChange}
        />
      </>
    );
  };

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"お知らせの" + (props.modeEditInformation === 'ADD' ? '新規登録' : '更新')}</DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: (theme) => theme.spacing(1),
        },
      }}>
        <Paper sx={{
          minWidth: 240,
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(3),
        }} elevation={3}>
          {createContents()}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOrignOk} color="primary">
          OK
        </Button>
        <Button onClick={props.handleClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
      <Dialog
        open={openSubDialog}
        keepMounted
        onClose={closeSubDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">お知らせの削除</DialogTitle>
        <DialogContent>
          <Typography>{subMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSubDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};
