import React from 'react'
import { Route, Routes } from 'react-router'

import AppBarContainer from '../containers/appBarContainer';
import MainContainer from '../containers/MainContainer';
import ApprovalConteiner from '../containers/approvalConteiner';
import SearchContainer from '../containers/searchContainer';
import EditingContainer from '../containers/EditingContainer';
import WhatsnewContainer from '../containers/whatsnewContainer';
import TagsManagementContainer from '../containers/TagsManagementContainer';
import RepealContainer from '../containers/repealContainer';
import InformationContainer from '../containers/InformationContainer';

const RouteError = () => <h2>RouteError</h2>;

// <Route path="/current" component={CurrentAppContainer} />
const routes = (
    <div>
        <header>
            <AppBarContainer />
        </header>
        <Routes>
            <Route index element={<MainContainer />} />
            <Route path="/index.html" element={<MainContainer />} />
            <Route path="/search" element={<SearchContainer />} />
            <Route path="/editing" element={<EditingContainer />} />
            <Route path="/whatsnew" element={<WhatsnewContainer />} />
            <Route path="/approval" element={<ApprovalConteiner />} />
            <Route path="/tags" element={<TagsManagementContainer />} />
            <Route path="/repeal" element={<RepealContainer />} />
            <Route path="/informations" element={<InformationContainer />} />
            <Route path="*" element={<RouteError />} />
        </Routes>
    </div>
)

export default routes