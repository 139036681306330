import { connect } from 'react-redux';

import { approvalAction } from '../actions/approvalAction';
import { appActions } from '../actions/appAction';
import { Approval } from '../components/Approval';
import { EditStateAPIParam } from '../actions/editingActsAction';
import { ActInfo, EditActInfo, EnforceInfo } from '../states/approvalState';

export interface ApprovalActions {
    getApprovalActs: (accountid: string, id: string) => void;
    approval: (accountid: string, editingId: string, actId: string, idid: string, id: string, editType: string) => void;
    scrutiny: (accountid: string, editingId: string, actId: string, idid: string, id: string, editType: string) => void;
    setLoading: (loading: boolean) => void;
    setApprovalErr: (err: boolean) => void;
    putEditStateAsync: (pram: EditStateAPIParam, rejection: boolean) => void;
    commitEditActInfoAsync: (param: EditStateAPIParam, detail: EditActInfo) => void,
    commitActInfoAsync: (param: EditStateAPIParam, detail: ActInfo) => void,
    commitEnforceInfoAsync: (param: EditStateAPIParam, detail: EnforceInfo) => void,
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getApprovalActs: (accountid: string, id: string) => dispatch(approvalAction.getApprovalActsAsync(accountid, id)),
        approval: (accountid: string, editingId: string, actId: string, idid: string, id: string, editType: string) => dispatch(approvalAction.approvalAsync(accountid, editingId, actId, idid, id, editType)),
        scrutiny: (accountid: string, editingId: string, actId: string, idid: string, id: string, editType: string) => dispatch(approvalAction.scrutinyAsync(accountid, editingId, actId, idid, id, editType)),
        setLoading: (loading: boolean) => dispatch(appActions.setLoading(loading)),
        setApprovalErr: (err: boolean) => dispatch(approvalAction.setApprovalError(err)),
        putEditStateAsync: (param: EditStateAPIParam, rejection: boolean) => dispatch(approvalAction.putEditStateAsync(param, rejection)),
        commitEditActInfoAsync: (param: EditStateAPIParam, detail: EditActInfo) => dispatch(approvalAction.commitEditActInfoAsync(param, detail)),
        commitActInfoAsync: (param: EditStateAPIParam, detail: ActInfo) => dispatch(approvalAction.commitActInfoAsync(param, detail)),
        commitEnforceInfoAsync: (param: EditStateAPIParam, detail: EnforceInfo) => dispatch(approvalAction.commitEnforceInfoAsync(param, detail)),
    };
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign({}, state.approvalActsState, state.appState);
};

export default connect(mapStateToProps, mapDispatchToProps)(Approval);
