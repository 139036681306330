import { connect } from 'react-redux';

import { whatsnewAction } from '../actions/whatsnewAction';
import { appActions } from '../actions/appAction';
import { Whatsnew } from '../components/Whatsnew';

export interface WhatsnewActions {
  getWhatsnewActsTables: (accountId: string, id:string) => void;
  setLoading: (loading: boolean) => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getWhatsnewActsTables: (accountId: string, id:string) => dispatch(whatsnewAction.getWhatsnewAsync(accountId, id)),
    setLoading: (loading: boolean) => dispatch(appActions.setLoading(loading)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, state.whatsnewActsState, state.appState);
};

export default connect(mapStateToProps, mapDispatchToProps)(Whatsnew);
