import { Action } from 'typescript-fsa';
import { connect } from 'react-redux';

import { ApplicationState } from '../store/store';
import { appActions } from '../actions/appAction';
import { searchActions } from '../actions/searchAction';
import { SampleAppBar } from '../components/AppBar';
import { approvalAction } from '../actions/approvalAction';
import { editingAction } from '../actions/editingAction';
import { whatsnewAction } from '../actions/whatsnewAction';
import { repealActions } from '../actions/repealAction';
import { informationActions } from '../actions/informationAction';
import { Conditions } from '../states/searchState';

export interface AppBarActions {
    getApprovalActs: (accountid: string, id: string) => void;
    getEditingAsync: (accountid: string, id: string) => void;
    getWhatsnewAsync: (accountid: string, id: string) => void;
    getInformationAsync: (accountid: string, id: string) => void;
    getProfile: (v: string) => Action<string>;
    searchAsync: (c: Conditions, accountid: string, id: string) => void;
    searchRepealAsync: (c: Conditions, accountid: string) => void;
    setOpenNewActDialog: (openNewActDialog: boolean) => void,
    setOpenPubContentsDialog: (openPubContentsDialog: boolean) => void,
    getPubContentsItemAsync: (accountid: string) => void;
    setLoading: (loading: boolean) => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getApprovalActs: (accountid: string, id: string) => dispatch(approvalAction.getApprovalActsAsync(accountid, id)),
        getEditingAsync: (accountid: string, id: string) => dispatch(editingAction.getEditingAsync(accountid, id)),
        getWhatsnewAsync: (accountid: string, id: string) => dispatch(whatsnewAction.getWhatsnewAsync(accountid, id)),
        getInformationAsync: (accountid: string, id: string) => dispatch(informationActions.getInformationsAsync(accountid, id)),
        getProfile: (v: string) => dispatch(appActions.getProfile(v)),
        searchAsync: (c: Conditions, accountid: string, id: string) => dispatch(searchActions.searchAsync(c, accountid, id)),
        searchRepealAsync: (c: Conditions, accountid: string) => dispatch(repealActions.searchRepealAsync(c, accountid)),
        setOpenNewActDialog: (openNewActDialog: boolean) => dispatch(appActions.setOpenNewActDialog(openNewActDialog)),
        setOpenPubContentsDialog: (openPubContentsDialog: boolean) => dispatch(appActions.setOpenPubContentsDialog(openPubContentsDialog)),
        getPubContentsItemAsync: (accountid: string) => dispatch(appActions.getPubContentsItemAsync(accountid)),
        setLoading: (loading: boolean) => dispatch(appActions.setLoading(loading)),
    };
};

const mapStateToProps = (applicationState: ApplicationState) => {
    return Object.assign({}, applicationState.appState);
};

export default connect(mapStateToProps, mapDispatchToProps)(SampleAppBar);
