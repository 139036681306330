import React from 'react';

import { API, Storage } from 'aws-amplify';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { PubContents } from '../states/appState';

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

type Props = {
  open: boolean;
  pubContents: PubContents[];
  accountid: string;
  idid: string;
  handleClose: () => void;
  handleOk: () => void;
  setLoading: (loading: boolean) => void;
};

export const PubContentsDialog: React.FC<Props> = (props) => {

  const handleDownload = async (type) => {
    try {
      props.setLoading(true);
      const myInit = {
        headers: {},
      };
      const url1 = `/pubcontents/download?key=${props.pubContents[type].key}&idid=${props.idid}`;
      const res = await API.get(API_NAME, url1, myInit);

      const tmp: string = res.outPath;
      const newOutPath = tmp.replace(/private\/.*?\//, '');
      downloadForm(newOutPath);

    } catch (e) {
      // エラー
      console.log(e);
    }

  };

  const downloadForm = async (path: string) => {
    const file = await Storage.get(path, { level: 'private' });
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '' + file);
    xhr.responseType = 'blob';
    xhr.onloadend = function () {
      if (xhr.status !== 200) {
        return false;
      }
      //const blob = new Blob([xhr.response], { "type" : "text/plain" });
      //const blob = new Blob([xhr.response], { "type" : "application/msword" });
      //const blob = new Blob([xhr.response], { "type" : "image/jpeg" });
      const blob = new Blob([xhr.response]);
      const link = document.createElement('a');
      link.download = path;
      link.href = window.URL.createObjectURL(blob);
      link.click();
      //window.navigator.msSaveOrOpenBlob(xhr.response, fileName);
      props.setLoading(false);
    };
    xhr.send();
  };

  const createContents = () => {
    const createLimited = () => {
      return props.pubContents[1].key ? (
        <Paper sx={{
          minWidth: 500,
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(1),
        }} elevation={3}>
          <Typography sx={{ margin: 1, }} variant="body1" gutterBottom>
            作成日時：{props.pubContents[1].date}
          </Typography>
          <Typography sx={{ margin: 1, }} variant="body1" gutterBottom>
            作成タイプ：{props.pubContents[1].executeMode === 'limited' ? '外部公開版(外部公開設定された文書のみ)' : '全文書版(外部非公開設定された文書も含めた全文書)'}
          </Typography>
          <Button onClick={() => handleDownload(1)} color="primary">
            ダウンロード
          </Button>
        </Paper>
      ) : (
        <Paper sx={{
          minWidth: 500,
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(1),
        }} elevation={3}>
          <Typography sx={{ margin: 1, }} variant="body1" gutterBottom>
            ダウンロード可能な外部公開版(外部公開設定された文書のみ)コンテンツは存在しません。
          </Typography>
        </Paper>
      );
    };
    const createUnlimited = () => {
      return props.pubContents[0].key ? (
        <Paper sx={{
          minWidth: 500,
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(1),
        }} elevation={3}>
          <Typography sx={{ margin: 1, }} variant="body1" gutterBottom>
            作成日時：{props.pubContents[0].date}
          </Typography>
          <Typography sx={{ margin: 1, }} variant="body1" gutterBottom>
            作成タイプ：{props.pubContents[0].executeMode === 'limited' ? '外部公開版(外部公開設定された文書のみ)' : '全文書版(外部非公開設定された文書も含めた全文書)'}
          </Typography>
          <Button onClick={() => handleDownload(0)} color="primary">
            ダウンロード
          </Button>
        </Paper>
      ) : (
        <Paper sx={{
          minWidth: 500,
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(1),
        }} elevation={3}>
          <Typography sx={{ margin: 1, }} variant="body1" gutterBottom>
            ダウンロード可能な全文書版(外部非公開設定された文書も含めた全文書)コンテンツは存在しません。
          </Typography>
        </Paper>
      );
    };
    return props.pubContents.length === 2 ? (
      <>
        {createUnlimited()}
        {createLimited()}
      </>
    ) : (
      <Paper sx={{
        minWidth: 500,
        margin: (theme) => theme.spacing(1),
        padding: (theme) => theme.spacing(1),
      }} elevation={3}>
        <Typography sx={{ margin: 1, }} variant="body1" gutterBottom>
          ダウンロード対象のコンテンツが存在しません。
        </Typography>
      </Paper>
    );
  };

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"外部公開コンテンツダウンロード"}</DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: (theme) => theme.spacing(1),
        },
      }}>
        {createContents()}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog >
  );

};