import { connect } from 'react-redux';

import { History } from 'history';

import { appActions } from '../actions/appAction';
import { currentAppAction } from '../actions/currentAppAction';
import App from '../App';

export interface AppActions {
    initApplicationsAsync: (pathname: string) => void;
    getCurrentActTables: () => void;
    setOpenNewActDialog: (openNewActDialog: boolean) => void,
    setOpenPubContentsDialog: (openPubContentsDialog: boolean) => void,
    getActtemplate: (accountId: string) => void,
    setLoading: (loading: boolean) => void;
}

export interface OwnProps {
    history: History;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        initApplicationsAsync: (pathname: string) => dispatch(appActions.initApplicationsAsync(pathname)),
        getCurrentActTables: () => dispatch(currentAppAction.getCurrentActTablesAsync()),
        setOpenNewActDialog: (openNewActDialog: boolean) => dispatch(appActions.setOpenNewActDialog(openNewActDialog)),
        setOpenPubContentsDialog: (openPubContentsDialog: boolean) => dispatch(appActions.setOpenPubContentsDialog(openPubContentsDialog)),
        getActtemplate: (accountId: string) => dispatch(appActions.getActtemplateAsync(accountId)),
        setLoading: (loading: boolean) => dispatch(appActions.setLoading(loading)),
    };
}

const mapStateToProps = (state, history) => {
    return Object.assign({}, state.appState, history.history);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);