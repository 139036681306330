import React from 'react';

import { Storage, API } from 'aws-amplify';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';

import { styled } from '@mui/material/styles'

import { Acttemplate } from '../states/appState';

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

const Input = styled('input')(({ theme }) => ({
  opacity: 0,
  appearance: 'none',
  position: 'absolute',
  width: 1,
}));

type Props = {
  open: boolean;
  accountid: string;
  idid: string;
  id: string;
  acttemplate: Acttemplate[];
  handleClose: () => void;
  handleOk: () => void;
  getActtemplate: (accountId: string) => void,
  setLoading: (loading: boolean) => void;
};

type UploadFile = {
  name: string;
  fileObj: any;
};

type ActImportOk = {
  actId: string;
  editingId: string;
  stepId: string;
  warnList?: ActImportErrorWarn[];
};

type ActImportErrorWarn = {
  lineText: string;
  message: string;
  nodeId: string;
  nodeName: string;
};

export const NewActDialog: React.FC<Props> = (props) => {

  const [phase, setPhase] = React.useState<number>(0);

  const [fValue, setFValue] = React.useState<string>('0');
  const handleFValueChange = (event) => {
    setFValue(event.target.value);
  };

  // テキスト取込
  const [uploadFile, setUploadFile] = React.useState<UploadFile | undefined>();
  const handleSetFile = (file) => {
    // ファイル名に日本語が含まれるとうまくいかないため、仮の名前に置き換える
    const newName = '' + new Date().getTime() + '.' + file.name.split('.').pop();
    setUploadFile({ name: newName, fileObj: file });
  };
  const handleRemoveFile = () => {
    setUploadFile(undefined);
  };
  const selectedFile = (e) => {
    // TODO ファイル形式チェック
    if (!e.target.files[0]) return;
    handleSetFile(e.target.files[0]);
  };

  const [title, setTitle] = React.useState<string>('');
  const handleTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const newTxt = value.replace(/[\s]/g, '');

    setTitle(newTxt);
  };

  const [actImportOk, setActImportOk] = React.useState<ActImportOk>({ actId: '', editingId: '', stepId: '' });
  const [actImportError, setActError] = React.useState<ActImportErrorWarn[]>([]);

  // テンプレート
  const [templateValue, setTemplateValue] = React.useState<string>('0');
  const handleTemplateValueChange = (event) => {
    setTemplateValue(event.target.value);
    setSelectedTemplate(props.acttemplate.find((t) => t.templateId === event.target.value));
  };
  const [selectedTemplate, setSelectedTemplate] = React.useState<Acttemplate | undefined>();

  const createThrirdPhaseError = (errors: ActImportErrorWarn[]) => {
    return errors.map((e, index) => {
      return (
        <div key={"" + index}>
          <ListItem>
            <ListItemIcon sx={{
              color: (theme) => theme.palette.primary.main,
            }}>
              <ErrorOutlineSharpIcon color='secondary' />
            </ListItemIcon>
            <ListItemText primary={e.nodeName} secondary={
              <Typography variant="body1" component="span">{e.message}</Typography>
            } />
          </ListItem>
          <Divider />
        </div>
      );
    });
  };

  const createThrirdPhase = () => {
    if (fValue === '0') {
      return actImportError.length === 0 ? (
        '取り込みに成功しました。'
      ) : (
        <Paper sx={{
          minWidth: 240,
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(1),
        }} elevation={3}>
          <Typography variant="h5">
            取り込みに失敗しました。
          </Typography>
          <List component="nav" aria-label="Information">
            {createThrirdPhaseError(actImportError)}
          </List>
        </Paper>
      );
    } else {
      return '編集データの作成に成功しました。';
    }
  };

  const createTemplateList = () => {
    return props.acttemplate.map((t) => {
      const txt = t.name + (t.remark ? t.remark : '');
      return (
        <FormControlLabel key={t.templateId} value={t.templateId} control={<Radio />} label={txt} />
      );
    });
  };

  const createSecondPhase = () => {
    if (fValue === '0') {
      const newFileObj = uploadFile ? (
        <>
          <label style={{ 'margin': 10 }}>{uploadFile && uploadFile.fileObj.name}</label>
          <Button color="secondary" variant='outlined' component='span' onClick={handleRemoveFile}>キャンセル</Button>
        </>
      ) : '';
      return (
        <>
          <span style={{ marginLeft: '10px' }}>
            <TextField
              id={'id'}
              label={'題名'}
              multiline
              value={title}
              sx={{
                marginLeft: (theme) => theme.spacing(1),
                marginRight: (theme) => theme.spacing(1),
                width: 300,
              }}
              onChange={handleTitle}
              margin="normal"
              variant="outlined"
            />
            <Input type='file' id={'file-input_actTitle001'} onChange={selectedFile} />
            <br />
            <label htmlFor={'file-input_actTitle001'} style={{ 'marginLeft': 10 }}>
              <Button color="secondary" variant='outlined' component='span'>ファイルを選択</Button>
            </label>
            {newFileObj}
          </span>
        </>
      );
    } else {
      return (
        <FormControl component="fieldset">
          <FormLabel component="legend">テンプレートを選択</FormLabel>
          <RadioGroup aria-label="gender" name="gender1" value={templateValue} onChange={handleTemplateValueChange}>
            {createTemplateList()}
          </RadioGroup>
        </FormControl>
      );
    }
  }

  const createFirstPhase = () => {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">作成方法を選択</FormLabel>
        <RadioGroup aria-label="gender" name="gender1" value={fValue} onChange={handleFValueChange}>
          <FormControlLabel value="0" control={<Radio />} label="Docxファイルから作成" />
          <FormControlLabel value="1" control={<Radio />} label="テンプレートから作成" />
        </RadioGroup>
      </FormControl>
    );
  };

  const createContents = () => {
    return phase === 0 ? createFirstPhase() : phase === 1 ? createSecondPhase() :
      phase === 2 ? createThrirdPhase() : "";
  };

  const handleNext = async () => {
    if (phase === 0) {
      if (fValue === '1') {
        props.setLoading(true);
        props.getActtemplate(props.accountid);
      }
      setPhase(1);
    } else if (phase === 1) {
      if (fValue === '0') {
        if (uploadFile) {
          props.setLoading(true);

          await Storage.put(uploadFile.name, uploadFile.fileObj, {
            level: 'private',
            contentType: 'text/plain'
          });

          const myInit1 = {
            headers: {},
            body: {
              accountid: props.accountid,
              idid: props.idid,
              importFileName: uploadFile.name,
              title: title,
              initial: 'あ',
              id: props.id,
            },
          };
          const url1 = `/actimport`;
          const res1 = await API.post(API_NAME, url1, myInit1);

          if (res1.errorList) {
            setActError(res1.errorList);
          } else {
            setActImportOk(res1);
          }

          setPhase(2);
          props.setLoading(false);
        }
      } else {
        if (selectedTemplate) {
          props.setLoading(true);

          const myInit1 = {
            headers: {},
            body: {
              accountid: props.accountid,
              idid: props.idid,
              templateId: selectedTemplate.templateId,
              id: props.id,
            },
          };
          const url1 = `/acttemplate/edit`;
          const res1 = await API.post(API_NAME, url1, myInit1);

          setActImportOk(res1);

          setPhase(2);
          props.setLoading(false);
        }
      }
    } else {
      openEditor(actImportOk.editingId, actImportOk.actId, '0', actImportOk.stepId);
      handleThisClose();
    }
  };

  const handleThisClose = () => {
    setPhase(0);
    setFValue('0');
    handleRemoveFile();
    setTitle('');
    setActImportOk({ actId: '', editingId: '', stepId: '' });
    setActError([]);
    props.handleClose();
  };

  // 編集画面OPEN
  const openEditor = (editingId: string, actId: string, editType: string, stepId: string) => {
    // const pureEditingId = editingId.substring(editingId.indexOf('#') + 1);
    const query = `?actId=${actId}&editingId=${editingId}&editType=${editType}&stepId=${stepId}`;
    const url = process.env.REACT_APP_EDITOR_URL ? process.env.REACT_APP_EDITOR_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_EDITOR_URL;
    window.open(url + query, 'editor');
  };

  const createOk = () => {
    const disabled = fValue === '0' && phase === 2 && actImportError.length > 0 ? true :
      fValue === '0' && phase === 1 && !(uploadFile && title) ? true :
        fValue === '1' && phase === 1 && !selectedTemplate ? true : false;
    const txt = fValue === '0' && phase === 2 && actImportError.length === 0 ? '編集を開始する' :
      fValue === '1' && phase === 2 ? '編集を開始する' : '次へ';
    return (
      <Button onClick={handleNext} color="primary" disabled={disabled}>
        {txt}
      </Button>
    );
  };

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={handleThisClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"新規制定"}</DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: (theme) => theme.spacing(1),
        },
      }}>
        <Paper sx={{
          minWidth: 240,
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(1),
        }} elevation={3}>
          {createContents()}
        </Paper>
      </DialogContent>
      <DialogActions>
        {createOk()}
        <Button onClick={handleThisClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};