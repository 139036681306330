import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { editingAction } from '../actions/editingAction';
import { EditingActs, } from './editingState';

export interface MyEditingState {
    editingActs: EditingActs[];
};

const initialState: MyEditingState = {
    editingActs: [{
        id: '',
        editingActTables: [],
    }]
};

export const myEditingReducer = reducerWithInitialState(initialState)
    .case(editingAction.getMyEditing, (state, payload) => {
        return Object.assign({}, state, { editingActs: payload });
    });
