import React from 'react';

import { useNavigate } from "react-router-dom";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import NoteIcon from '@mui/icons-material/Note';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import EditIcon from '@mui/icons-material/Edit';
import Dashboard from '@mui/icons-material/Dashboard';
import Comment from '@mui/icons-material/Comment';
import Edit from '@mui/icons-material/Edit';
import Newspaper from '@mui/icons-material/Newspaper';

import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import { AppState } from '../states/appState';
import { EditingAct } from '../states/editingState';
import { MyEditingState } from '../states/myEditingState';
import { WhatsnewState } from '../states/whatsnewState';
import { InformationState } from '../states/informationState';
import { MainActions } from '../containers/MainContainer';

type MainProps = AppState & MainActions & MyEditingState & WhatsnewState & InformationState;

const EDIT_TYPE = [
  '新規制定',
  '一部改正',
  '全部改正',
  '廃止',
];

const getTitle = (act: EditingAct) => {
  if (act.newTitle) {
    return act.newTitle + '[旧:' + act.title + ']';
  } else {
    return act.title;
  }
};

const openEditor = (editingId: string, actId: string, editType: string, stepId: string) => {
  const pureEditingId = editingId.substring(editingId.indexOf('#') + 1);
  const query = `?actId=${actId}&editingId=${pureEditingId}&editType=${editType}&stepId=${stepId}`;
  const url = process.env.REACT_APP_EDITOR_URL ? process.env.REACT_APP_EDITOR_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_EDITOR_URL;
  window.open(url + query, 'editor');
};
const editingView = (editingId: string, actId: string, stepId: string) => {
  const query = `?type=${'editor'}&actId=${actId}&editingId=${editingId}&stepId=${stepId}&miekesi=true`;
  const url = process.env.REACT_APP_VIEWER_URL ? process.env.REACT_APP_VIEWER_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_VIEWER_URL;
  window.open(url + query, `viewer_${actId}_${editingId}_${stepId}`);
};

export const Main: React.FC<MainProps> = (props) => {
  const navigate = useNavigate();

  const editing = () => {
    props.setLoading(true);
    props.getEditingAsync(props.accountid, props.id);
    navigate('/editing');
  };

  const whatsnew = () => {
    props.setLoading(true);
    props.getWhatsnewAsync(props.accountid, props.id);
    navigate('/whatsnew');
  };

  const createEditingAct = () => {
    if (props.editingActs.length === 0) {
      return '';
    }
    const item = props.editingActs[0].editingActTables.slice(0, 5);
    return item.map((i, index) => {
      return (
        <div key={"" + index}>
          <ListItem button onClick={() => editingView(i.editingId, i.actId, i.stepId)}>
            <ListItemIcon sx={{
              color: (theme) => theme.palette.primary.main,
            }}>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary={getTitle(i)} secondary={
              <>
                <Typography variant="subtitle1" component="span">番号：</Typography>
                <Typography variant="body1" component="span">{i.actNum}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">改正番号：</Typography>
                <Typography variant="body1" component="span">{i.editActNum}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">編集種別：</Typography>
                <Typography variant="body1" component="span">{EDIT_TYPE[Number.parseInt(i.editType)]}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">更新日：</Typography>
                <Typography variant="body1" component="span">{i.updateDate}</Typography>
              </>
            } />
            <ListItemSecondaryAction>
              <Tooltip title="編集を再開する">
                <IconButton sx={{
                  color: (theme) => theme.palette.secondary.main,
                }} edge="end" aria-label="edit" onClick={() => openEditor(i.editingId, i.actId, i.editType, i.stepId)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </div>
      )
    });
  };

  const createEditing = () => {
    return props.role === 'Viewer' ? '' : (
      <Paper sx={{
        minWidth: 275,
        width: '95%',
        margin: 2,
        padding: 1,
        backgroundColor: (theme) => theme.palette.primary[50],
      }} elevation={3}>
        <Typography sx={{ margin: 2 }} variant="h5" gutterBottom>
          <Edit fontSize='large' color='primary' sx={{ marginBottom: 1, marginRight: 1, verticalAlign: 'middle' }} />
          自分が担当する編集中データ
        </Typography>
        <Divider />
        <List component="nav" aria-label="My editing acts">
          {createEditingAct()}
        </List>
        <Button sx={{
          color: (theme) => theme.palette.secondary.main,
        }} size="small" onClick={editing}>さらに表示</Button>
      </Paper>
    );
  }

  const createWhatsnewAct = () => {
    const item = props.whatsnews.slice(0, 5);
    return item.map((i, index) => {
      return (
        <div key={"" + index}>
          <ListItem button onClick={() => editingView(i.editingId, i.actId, i.stepIds[0])}>
            <ListItemIcon sx={{
              color: (theme) => theme.palette.primary.main,
            }}>
              <FiberNewIcon />
            </ListItemIcon>
            <ListItemText primary={i.title} secondary={
              <>
                <Typography variant="subtitle1" component="span">番号：</Typography>
                <Typography variant="body1" component="span">{i.actNum}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">改正番号：</Typography>
                <Typography variant="body1" component="span">{i.editActNum}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">編集種別：</Typography>
                <Typography variant="body1" component="span">{EDIT_TYPE[Number.parseInt(i.editType)]}</Typography>
              </>
            } />
          </ListItem>
          <Divider />
        </div>
      )
    });
  };

  const [dispInfo, setDispInfo] = React.useState(Array(5).fill(false));

  const handleInfoClick = (index: number) => {
    const copy = dispInfo.slice();
    copy[index] = !copy[index];
    setDispInfo(copy);
  };

  const createInformation = () => {
    const createItemIcon = (type: number) => {
      return type === 0 ? (
        <ListItemIcon sx={{
          color: (theme) => theme.palette.primary.main,
        }}>
          <InfoIcon />
        </ListItemIcon>
      ) : type === 1 ? (
        <ListItemIcon sx={{
          color: "#ffb74d",
        }}>
          <WarningIcon />
        </ListItemIcon>
      ) : (
        <ListItemIcon sx={{
          color: "#ff4500",
        }}>
          <LabelImportantIcon />
        </ListItemIcon>
      );
    };

    // textのBR
    // 表示を5行に制限
    const createOmmitText = (text: string) => {
      const t = text.split(/\n/);
      const nt = t.slice(0, 5).map((s, index) => (
        <span key={index}>{s}<br /></span>
      ));
      if (t.length > 5) {
        nt.push((<span key={'end'}>…<br /></span>));
      }
      return nt;
    };
    const createText = (text: string) => {
      return text.split(/\n/).map((s, index) => (
        <span key={index}>{s}<br /></span>
      ));
    };

    const createPeriod = (e: string) => {
      return e ? '～' + e + 'まで' : '';
    };

    return props.informations.slice(0, 5).map((i, index) => {
      const getUser = () => {
        // return i.from === 'Manager' ? (<Tooltip title="管理者"><Typography variant="body1" component="span">{i.userName}<ManageAccountsIcon sx={{color: (theme) => theme.palette.secondary.main, marginLeft: 2, verticalAlign: -5}} /></Typography></Tooltip>)
        //   : (<Typography variant="body1" component="span">{i.userName}</Typography>);
        return (<Typography variant="body1" component="span">{i.userName}</Typography>);
      }
      return (
        <div key={"" + index}>
          <ListItemButton selected={dispInfo[index]} onClick={() => handleInfoClick(index)}>
            {createItemIcon(i.type)}
            <ListItemText primary={i.title} secondary={
              <>
                <Typography variant="subtitle1" component="span">登録者：</Typography>
                {getUser()}<br />
                <Typography variant="subtitle1" component="span">掲載期間：</Typography>
                <Typography variant="body1" component="span">{i.startDate}{createPeriod(i.endDate)}</Typography><br /><br />
                {dispInfo[index] ? createText(i.text) : createOmmitText(i.text)}<br />
              </>
            } />
          </ListItemButton>
          <Divider />
        </div>
      );
    });
  };

  const handleMoreInformation = () => {
    props.setLoading(true);
    props.getInformationsAsync(props.accountid, props.id);
    navigate('/informations');
  };

  return (
    <>
      <Typography sx={{ margin: 2 }} variant="h5" gutterBottom>
        <Dashboard fontSize='large' color='primary' sx={{ marginBottom: 1, marginRight: 1, verticalAlign: 'middle' }} />
        ダッシュボード
      </Typography>
      <Paper sx={{
        minWidth: 275,
        width: '95%',
        margin: 2,
        padding: 1,
        backgroundColor: (theme) => theme.palette.primary[50],
      }} elevation={3}>
        <Typography sx={{ margin: 2 }} variant="h5" gutterBottom>
          <Comment fontSize='large' color='primary' sx={{ marginBottom: 1, marginRight: 1, verticalAlign: 'middle' }} />
          お知らせ
        </Typography>
        <Divider />
        <List component="nav" aria-label="Information">
          {createInformation()}
        </List>
        <Button sx={{
          color: (theme) => theme.palette.secondary.main,
        }} size="small" onClick={handleMoreInformation}>さらに表示</Button>
      </Paper>
      {createEditing()}
      <Paper sx={{
        minWidth: 275,
        width: '95%',
        margin: 2,
        padding: 1,
        backgroundColor: (theme) => theme.palette.primary[50],
      }} elevation={3}>
        <Typography sx={{ margin: 2 }} variant="h5" gutterBottom>
          <Newspaper fontSize='large' color='primary' sx={{ marginBottom: 1, marginRight: 1, verticalAlign: 'middle' }} />
          最近更新された文書
        </Typography>
        <Divider />
        <List component="nav" aria-label="What's New">
          {createWhatsnewAct()}
        </List>
        <Button sx={{
          color: (theme) => theme.palette.secondary.main,
        }} size="small" onClick={whatsnew}>さらに表示</Button>
      </Paper>
    </>
  );
};