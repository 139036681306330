import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { appActions } from '../actions/appAction';

export interface AppState {
    id: string;
    name: string;
    email: string;
    accountid: string;
    govName: string;
    govNameSuffix: string;
    userGroupId: string;
    startDate: string;
    role: string;
    idid: string;
    loading: boolean;
    editStateList: { id: string, name: string }[];
    editTypeList: { id: string, name: string }[];
    openNewActDialog: boolean;
    openPubContentsDialog: boolean;
    pubContents: PubContents[];
    acttemplate: Acttemplate[];
}

export interface Acttemplate {
    accountId: string;
    templateId: string;
    name: string;
    viewOrder: number;
    remark: string;
}

export interface PubContents {
    key: string;
    date: string;
    executeMode: string;
}

const initialState: AppState = {
    id: 'ABCD',
    name: '',
    email: 'test@test.co.jp',
    accountid: 'xxxxssss',
    govName: '',
    govNameSuffix: '',
    userGroupId: '-1',
    startDate: '',
    role: 'Viewer',
    idid: 'qwerty',
    loading: false,
    editStateList: [{ id: '', name: '' }],
    editTypeList: [{ id: '', name: '' }],
    openNewActDialog: false,
    openPubContentsDialog: false,
    pubContents: [],
    acttemplate: [],
};

export const appReducer = reducerWithInitialState(initialState)
    .case(appActions.initProfile, (state, payload) => {
        return Object.assign({}, state, {
            id: payload.id, name: payload.name, email: payload.email,
            accountid: payload.accountid, startDate: payload.startDate, govName: payload.govName, govNameSuffix: payload.govNameSuffix,
            userGroupId: payload.userGroupId, role: payload.role, idid: payload.idid
        });
    }).case(appActions.setLoading, (state, payload) => {
        return Object.assign({}, state, { loading: payload });
    }).case(appActions.initEditstateList, (state, payload) => {
        return Object.assign({}, state, { editStateList: payload, });
    }).case(appActions.initEditTypeList, (state, payload) => {
        return Object.assign({}, state, { editTypeList: payload, });
    }).case(appActions.setOpenNewActDialog, (state, payload) => {
        return Object.assign({}, state, { openNewActDialog: payload });
    }).case(appActions.setOpenPubContentsDialog, (state, payload) => {
        return Object.assign({}, state, { openPubContentsDialog: payload });
    }).case(appActions.getPubContentsItem, (state, payload) => {
        return Object.assign({}, state, { pubContents: payload, });
    }).case(appActions.getActtemplate, (state, payload) => {
        return Object.assign({}, state, { acttemplate: payload, });
    });
