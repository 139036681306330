import actionCreatorFactory from 'typescript-fsa';
import { Dispatch, Action } from 'redux';
import { API } from 'aws-amplify';

import { appActions } from '../actions/appAction';

export interface EditingAct {
    stepId: any;
    editingId: string;
    actId: string;
    title: string;
    newTitle:string;
    editType: string;
    actNum: string;
    editActNum: string;
    editState: string;
};

export interface EditStateAPIParam {
    editingId: string;
    actId: string;
    editState: string;
    editType?: string;
    accountId: string;
    idid: string;
    id: string;
};

const actionCreator = actionCreatorFactory();

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

export const editingActsAction = {
    getEditingActTables: actionCreator<EditingAct[]>('ACTIONS_GET_EDITING_ACT_TABLES'),
    getEditingActTablesAsync: (accountId: string,) => {
        return async (dispatch: Dispatch<Action>) => {
            try {
                const myInit = {
                    headers: {}
                }
                // &state=editing 付与で編集終了前の一覧を取得
                const res = await API.get(API_NAME, `/editingacts?accountid=${accountId}&state=editing`, myInit);
                const tables = res.map(item => {
                    const result: EditingAct = {
                        editingId: item.editingId,
                        actId: item.actId,
                        title: item.title,
                        newTitle:item.newTitle,
                        editType: item.editType,
                        actNum: item.actNum,
                        editActNum: item.editActNum,
                        editState: item.editState,
                        stepId:item.stepId,
                    };
                    return result;
                });
                dispatch(editingActsAction.getEditingActTables(tables));
            } catch (err) {
                console.log(err);
            }
            dispatch(appActions.setLoading(false));
        };
    },
    deleteEditingActAsync: (accountId: string, idid: string, actId: string, editingId: string) => {
        return async (dispatch: Dispatch<Action>) => {
            try {
                const url = `/acts/${actId}/editings/${editingId}?accountid=${accountId}&idid=${idid}`;
                const myInit = {
                    headers: {}
                }
                await API.del(API_NAME, url, myInit);
                // 再取得
                await editingActsAction.getEditingActTablesAsync(accountId)(dispatch);
            } catch (err) {
                console.log(err);
            }
            dispatch(appActions.setLoading(false));
        }
    },
    putEditStateAsync: (param: EditStateAPIParam) => {
        return async (dispatch: Dispatch<Action>) => {
            try {
                // 編集状態更新
                const myInit = {
                    headers: {},
                    body: {
                        accountid: param.accountId,
                        id: param.id,
                        editState: param.editState,
                    },
                }
                const url = `/acts/${param.actId}/editings/${param.editingId}/editstate`;
                await API.put(API_NAME, url, myInit);
                // 再取得
                await editingActsAction.getEditingActTablesAsync(param.accountId)(dispatch);
            } catch (err) {
                console.log(err);
            }
            dispatch(appActions.setLoading(false));
        }
    }
}