import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
    loading: boolean;
}

export const LoadingDialog: React.FC<Props> = (props) => {
    return (
        <Dialog
            open={props.loading}
            keepMounted
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="max-width-dialog-title">読み込み中</DialogTitle>
            <DialogContent>
                <CircularProgress />
            </DialogContent>
        </Dialog>
    );
}