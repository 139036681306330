import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Amplify, Hub, I18n } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import './App.css';
import { AppState } from './states/appState';
import { AppActions, OwnProps } from './containers/appContainer';
import { LoadingDialog } from './components/LoadingDialog';
import { NewActDialog } from './components/NewActDialog';
import { PubContentsDialog } from './components/PubContentsDialog';

import AuthenticatorFormFields from './AuthenticatorFormFields';
import AuthenticatorComponent from './AuthenticatorComponent';

import routes from './routes'

import Fab from '@mui/material/Fab';
import Help from '@mui/icons-material/Help';
import Tooltip from '@mui/material/Tooltip';

type AppProps = OwnProps & AppState & AppActions;

Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_AMPLIFY_AUTH_IDENTITYPOOLID,
    region: process.env.REACT_APP_AWS_REGION,
    identityPoolRegion: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_AUTH_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_AUTH_USERPOOLWEBCLIENTID,
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_API_1_NAME,
        endpoint: process.env.REACT_APP_API_1_ENDPOINT,
        region: process.env.REACT_APP_AWS_REGION,
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_STRAGE_BUCKET,
      region: process.env.REACT_APP_AWS_REGION,
    }
  }
});

const dict = {
  ja: {
    "Forgot your password?": "パスワードを忘れた場合",
    "Sign in": "サインイン",
    "Back to Sign In": "サインインへ戻る",
    "Send code": "送信",
    "Submit": "送信",
    "Resend Code": "検証コードを再送信",
    "Change Password": "パスワードを設定",
    "Password": "パスワード",
    "Confirm Password": "もう一度入力",
    "Enter your Password": "パスワードを入力",
    "Please confirm your Password": "パスワードをもう一度入力",
    'Incorrect username or password.': "ユーザー名またはパスワードが間違っています。",
    "Your passwords must match": "新しいパスワードと再入力パスワードが一致しません。",
    "Invalid verification code provided, please try again.": "検証コードが間違っています。",
    "Password does not conform to policy: Password not long enough": "パスワードは8文字以上を入力してください。（8文字以上の大文字小文字を含む英・数・記号）\\n 使用できる記号　^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + =",
    "Password does not conform to policy: Password must have numeric characters": "パスワードは数字を含めてください。（8文字以上の大文字小文字を含む英・数・記号）\\n 使用できる記号　^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + =",
    "Password does not conform to policy: Password must have uppercase characters": "パスワードは大文字を含めてください。（8文字以上の大文字小文字を含む英・数・記号）\\n 使用できる記号　^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + =",
    "Password does not conform to policy: Password must have symbol characters": "パスワードは記号を含めてください。（8文字以上の大文字小文字を含む英・数・記号）\\n 使用できる記号　^ $ * . [ ] { } ( ) ? - \" ! @ # % & / \\ , > < ' : ; | _ ~ ` + =",
    "Attempt limit exceeded, please try after some time.": "試行制限を超過しました。しばらくしてからもう一度お試しください",
    "Go to previous page": "前のページ",
    "Go to next page": "次のページ",
    "Temporary password has expired and must be reset by an administrator.": "仮パスワードの有効期限が切れています。管理者にご連絡ください。",
    "Request not allowed due to WAF block.": "アクセスが拒否されました。",
  },
};

// 作成した辞書を渡して反映させる
I18n.putVocabularies(dict);
I18n.setLanguage("ja");

export const App: React.FC<AppProps> = (props) => {

  useEffect(() => {
    Hub.listen("auth", async (data) => {
      if (data.payload.event === 'signIn') {
        window.location.reload();
      }
    });
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = () => {
    props.setLoading(true);
    const pathname = props.history.location && props.history.location.pathname ? props.history.location.pathname : '';
    props.initApplicationsAsync(pathname);
  };

  // new act
  const handleCloseNewActDialog = () => {
    props.setOpenNewActDialog(false);
  };

  // pub contents
  const handleClosePubContentsDialog = () => {
    props.setOpenPubContentsDialog(false);
  };

  const handleHelp = () => {
    const f = (s) => {
      switch (s) {
        case 'Approver':
        case 'Manager':
          return 'approver';
        case 'Editor':
          return 'editor';
        default:
          return 'viewer';
      }
    };
    const url = process.env.REACT_APP_HELP_URL ? process.env.REACT_APP_HELP_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_HELP_URL;
    const url2 = url ? url.replace('{role}', f(props.role)) : url;
    console.log(url2);
    window.open(url2, `help`);
};

  return (
    <Authenticator formFields={AuthenticatorFormFields} components={AuthenticatorComponent} hideSignUp={true}>
      <BrowserRouter>
        <NewActDialog
          open={props.openNewActDialog}
          accountid={props.accountid}
          idid={props.idid}
          id={props.id}
          acttemplate={props.acttemplate}
          handleClose={handleCloseNewActDialog}
          handleOk={handleCloseNewActDialog}
          getActtemplate={props.getActtemplate}
          setLoading={props.setLoading}
        />
        <PubContentsDialog
          open={props.openPubContentsDialog}
          accountid={props.accountid}
          idid={props.idid}
          pubContents={props.pubContents}
          handleClose={handleClosePubContentsDialog}
          handleOk={handleClosePubContentsDialog}
          setLoading={props.setLoading}
        />
        <Tooltip title="ヘルプ">
          <Fab color="primary" aria-label="list" sx={{
            position: 'fixed',
            zIndex: 2,
            bottom: (theme) => theme.spacing(3),
            right: (theme) => theme.spacing(3),
          }} onClick={handleHelp}>
            <Help />
          </Fab>
        </Tooltip>
        <LoadingDialog loading={props.loading} />
        {routes}
      </BrowserRouter>
    </Authenticator>
  );
}

export default App;
