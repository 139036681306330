import { connect } from 'react-redux';

import { editingAction, EditStateAPIParam } from '../actions/editingAction';
import { appActions } from '../actions/appAction';
import { Editing } from '../components/Editing';

export interface EditingActions {
    initProfile: () => void;
    getEditingAsync: (accountId: string, id: string) => void;
    setLoading: (loading: boolean) => void;
    deleteEditingActAsync: (accountId: string, idid: string, actId: string, editingId: string, id: string) => void;
    putEditStateAsync: (pram: EditStateAPIParam) => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getEditingAsync: (accountId: string, id: string) => dispatch(editingAction.getEditingAsync(accountId, id)),
        setLoading: (loading: boolean) => dispatch(appActions.setLoading(loading)),
        deleteEditingActAsync: (accountId: string, idid: string, actId: string, editingId: string, id: string) =>
            dispatch(editingAction.deleteEditingActAsync(accountId, idid, actId, editingId, id)),
        putEditStateAsync: (param: EditStateAPIParam) => dispatch(editingAction.putEditStateAsync(param))
    };
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign({}, state.editingActsState, state.appState);
};

export default connect(mapStateToProps, mapDispatchToProps)(Editing);
