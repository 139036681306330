import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { ThemeProvider, createTheme } from '@mui/material/styles'

import * as serviceWorker from './serviceWorker';
import './index.css';
import AppConteiner from './containers/appContainer';
import Store from './store/store';
import { history } from './store/store';

const theme = createTheme({
    // カラーパレット
    palette: {
        // メインカラー
        primary: {
            50: '#fff3e0',
            100: '#ffe0b2',
            200: '#ffcc80',
            300: '#ffb74d',
            400: '#ffa726',
            500: '#ff9800',
            600: '#fb8c00',
            700: '#f57c00',
            800: '#ef6c00',
            900: '#e65100',
            A100: '#ffd180',
            A200: '#ffab40',
            A400: '#ff9100',
            A700: '#ff6d00',
        },
        // アクセントカラー
        secondary: {
            50: '#e3f2fd',
            100: '#bbdefb',
            200: '#90caf9',
            300: '#64b5f6',
            400: '#42a5f5',
            500: '#2196f3',
            600: '#1e88e5',
            700: '#1976d2',
            800: '#1565c0',
            900: '#0d47a1',
            A100: '#82b1ff',
            A200: '#448aff',
            A400: '#2979ff',
            A700: '#2962ff',
        },
    },
    // レスポンシブレイアウト用の指定
    'breakpoints': {
        'keys': [
            'xs',
            'sm',
            'md',
            'lg',
            'xl',
        ],
        'values': {
            'xs': 360, // スマホ用
            'sm': 768, // タブレット用
            'md': 992, // PC用
            'lg': 1000000000,
            'xl': 1000000000,
        },
    },
});

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container);
    root.render(
        <ThemeProvider theme={theme}>
            <Provider store={Store}>
                <AppConteiner history={history} />
            </Provider>
        </ThemeProvider>
    );
}

serviceWorker.unregister();
