const AuthenticatorFormFields = {
    signIn: {
      username: {
        label: 'ユーザーID（メールアドレス） *',
        labelHidden: false,
        placeholder: 'ユーザーIDを入力',
        isRequired: true,
      },
      password: {
        label: 'パスワード *',
        labelHidden: false,
        placeholder: 'パスワードを入力',
        isRequired: true,
      },
    },
    resetPassword: {
      username: {
        label: 'ユーザーID',
        labelHidden: false,
        placeholder: 'ユーザーIDを入力',
        isRequired: true,
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        label: '検証コード',
        labelHidden: false,
        placeholder: '検証コードを入力',
        isRequired: false,
      },
      password: {
        label: '新しいパスワード',
        labelHidden: false,
        placeholder: '新しいパスワードを入力',
        isRequired: false,
      },
      confirm_password: {
        label: 'もう一度入力',
        labelHidden: false,
        placeholder: '新しいパスワードをもう一度入力',
        isRequired: false,
      },
    },
  }
  
  export default AuthenticatorFormFields