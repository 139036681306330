import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { approvalAction } from '../actions/approvalAction';

export interface ApprovalState {
  approvalActs: ApprovalActs[];
  actTypes: ActType[];
  approvalError: boolean;
};

export interface ApprovalActs {
  id: string,
  approvalActTables: ApprovalAct[]
};

export interface ApprovalAct {
  actId: string;
  editingId: string;
  actNum: string;
  editActNum: string;
  title: string;
  newTitle: string;
  editState: string;
  editType: string;
  updateDate: string;
  stepId: string;
  actInfo: ActInfo;
  editActInfo: EditActInfo;
  enforceInfo: EnforceInfo;
  stepIdInfo: number[];
};

export interface EditActInfo {
  publicationDate: string;
  editActType: string;
  editActNum: string;
  editActEdaNum?: string;
  editActMagoNum?: string;
  editActNumOption?: string;
  editActNumOptionViewFlag: boolean;
}

export interface ActInfo {
  publicationDate: string;
  actType: string;
  actNum: string;
  actEdaNum?: string;
  actMagoNum?: string;
  actNumOption?: string;
  actNumOptionViewFlag: boolean;
  expireDate: string;
  beforeEnactment: string;
  afterEnactment: string;
  preamble: string;
  enforceDate: string;
};

export interface EnforceInfo {
  enforceGroups: EnforceGroup[];
  changeGroups: ChangeGroup[];
  editType: number;
};

export interface EnforceGroup {
  enforceGroupId: string;
  enforceDate: string;
  informal: boolean;
};

export interface ChangeGroup {
  changeGroupId: string;
  enforceGroupId: string;
  stepId: number;
  revisionSentenceArray: string[];
};

export interface ActType {
  id: string;
  name: string;
}

const initialState: ApprovalState = {
  approvalActs: [
    {
      id: '',
      approvalActTables: []
    }
  ],
  actTypes: [
    { id: '0', name: 'なし' },
    { id: '1', name: '条例' },
    { id: '2', name: 'テスト' },],
  approvalError: false,
};

export const approvalReducer = reducerWithInitialState(initialState)
  .case(approvalAction.getApprovalActs, (state, payload) => {
    return Object.assign({}, state, { approvalActs: payload });
  }).case(approvalAction.setApprovalError, (state, payload) => {
    return Object.assign({}, state, { approvalError: payload });
  }).case(approvalAction.initActtype, (state, payload) => {
    return Object.assign({}, state, { actTypes: payload, });
  });
