import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

type Props = {
  open: boolean;
  editStateValue: string;
  editStateList: { id: string, name: string }[];
  handleRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClose: () => void;
  okEditStateDialog: () => void;
};

export const EditStateDialog: React.FC<Props> = (props) => {

  const createEditStateRadio = (p: Array<{ id: string, name: string }>) => {
    const l = p.map((v, index) => {
      return (<FormControlLabel key={v.id} value={v.id} control={<Radio />} label={v.name} />)
    });
    return (<>{l}</>);
  }

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"状態を変更する"}</DialogTitle>
      <DialogContent>
        <RadioGroup aria-label="gender" name="gender1" value={props.editStateValue} onChange={props.handleRadioChange}>
          {props.editStateList ? createEditStateRadio(props.editStateList) : 'null'}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.okEditStateDialog} color="primary">
          OK
        </Button>
        <Button onClick={props.handleClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
