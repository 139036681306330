import { connect } from 'react-redux';

import { appActions } from '../actions/appAction';
import { TagsManagement } from '../components/TagsManagement';

export interface TagsManagementActions {
    setLoading: (loading: boolean) => void;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        setLoading: (loading: boolean) => dispatch(appActions.setLoading(loading)),
    };
};

const mapStateToProps = (state) => {
    return Object.assign({}, state.searchingState, state.appState);
};

export default connect(mapStateToProps, mapDispatchToProps)(TagsManagement);
