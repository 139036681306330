import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';

import { EditingGroup, EnforceGroup } from '../states/searchState';

type Props = {
  open: boolean;
  editingGroup: EditingGroup | null;
  hadleChangeTargetDate: (eg: EditingGroup) => void;
  hadleChangeDate: () => void;
  handleClose: () => void;
};

export const ReEnforceDateDialog: React.FC<Props> = (props) => {

  const handleDateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newInfo: EditingGroup = JSON.parse(JSON.stringify(props.editingGroup));
    const index = Number.parseInt((event.target as HTMLInputElement).id) - 1;
    if (newInfo.enforceGroups[index].enforceDate !== (event.target as HTMLInputElement).value) {
      if (!newInfo.enforceGroups[index].enforceDateOrg) {
        newInfo.enforceGroups[index].enforceDateOrg = newInfo.enforceGroups[index].enforceDate;
        newInfo.enforceGroups[index].changed = true;
      } else if (newInfo.enforceGroups[index].enforceDateOrg === (event.target as HTMLInputElement).value) {
        delete newInfo.enforceGroups[index].enforceDateOrg;
        if (!newInfo.enforceGroups[index].hasOwnProperty('informalOrg')) {
          newInfo.enforceGroups[index].changed = false;
        }
      }
      newInfo.enforceGroups[index].enforceDate = (event.target as HTMLInputElement).value;
      props.hadleChangeTargetDate(newInfo);
    }
  };

  const handleInformalChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const index = ((event.target as HTMLInputElement).name).split('#')[1];
    const newInfo: EditingGroup = JSON.parse(JSON.stringify(props.editingGroup));
    if (newInfo.enforceGroups[index].informal !== (event.target as HTMLInputElement).checked) {
      if (!newInfo.enforceGroups[index].hasOwnProperty('informalOrg')) {
        newInfo.enforceGroups[index].informalOrg = newInfo.enforceGroups[index].informal;
        newInfo.enforceGroups[index].changed = true;
      } else {
        delete newInfo.enforceGroups[index].informalOrg;
        if (!newInfo.enforceGroups[index].enforceDateOrg) {
          newInfo.enforceGroups[index].changed = false;
        }
      }
      newInfo.enforceGroups[index].informal = (event.target as HTMLInputElement).checked;
      props.hadleChangeTargetDate(newInfo);
    }
  };

  const createEnforceDateEdit = (item: EnforceGroup, index) => {
    return (
      <div key={'div#' + index}>
        <TextField
          id={item.groupId}
          key={item.groupId + index}
          label={"施行日 " + item.groupId}
          type="date"
          value={item.enforceDate}
          onChange={handleDateChange}
          sx={{
            margin: (theme) => theme.spacing(1),
            width: 150,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormControlLabel
          sx={{
            margin: (theme) => theme.spacing(1),
          }}
          control={<Checkbox checked={item.informal} onChange={handleInformalChange}
            name={'informal#' + index} />}
          label="仮"
        />
      </div>
    );
  };

  const createEnforceDateView = (item: EnforceGroup, index) => {
    return (
      <div key={'div#' + index}>
        <TextField
          id={item.groupId}
          key={item.groupId + index}
          label={"施行日 " + item.groupId}
          value={item.enforceDate}
          sx={{
            margin: (theme) => theme.spacing(1),
            width: 150,
          }}
          disabled={true}
        />
      </div>
    );
  };

  const createEnforceDate = () => {
    return props.editingGroup ? props.editingGroup.enforceGroups.map((item, index) => {
      return item.informal || (!item.informal && item.informalOrg) ? createEnforceDateEdit(item, index) : createEnforceDateView(item, index);
    }) : '';
  };

  const [subDialog, setSubDialog] = React.useState(false);
  const openSubDialog = Boolean(subDialog);
  const closeSubDialog = () => setSubDialog(false);

  const handlePreOk = () => {
    if (props.editingGroup) {
      const pDate = props.editingGroup.enforceGroups[0].publicationDate;
      if (pDate) {
        const sPDate = new Date(pDate);
        const fEG = props.editingGroup.enforceGroups.find((e) => e.enforceDate && sPDate.getTime() > new Date(e.enforceDate).getTime());
        if (!fEG) {
          handleOk();
          return;
        }
      }
    }
    setSubDialog(true);
  }

  const handleOk = () => {
    props.hadleChangeDate();
    props.handleClose();
  };

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"本施行日を設定する"}</DialogTitle>
      <DialogContent sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: (theme) => theme.spacing(1),
        },
      }}>
        <Paper sx={{
          minWidth: 360,
          width: '40%',
          margin: (theme) => theme.spacing(1),
          padding: (theme) => theme.spacing(1),
        }} elevation={3}>
          {createEnforceDate()}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePreOk} color="primary">
          OK
        </Button>
      </DialogActions>
      <Dialog
        open={openSubDialog}
        keepMounted
        onClose={closeSubDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">施行日が正しくありません。</DialogTitle>
        <DialogContent>
          <Typography>施行日は公布日以降で入力してください。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSubDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );

};  
