import actionCreatorFactory from 'typescript-fsa';
import { Dispatch, Action } from 'redux';
import { API } from 'aws-amplify';

import { Catalog, SearchResult, Hit, Field, Conditions, EditingGroup } from '../states/searchState';
import { appActions } from '../actions/appAction';

const actionCreator = actionCreatorFactory();

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

const aggregationSearchResult = (res: SearchResult) => {
  const newRes: SearchResult = JSON.parse(JSON.stringify(res));
  const newHits: Hit[] = [];
  const ids: string[] = [];
  res.hit.forEach((hit) => {
    const id = hit.fields.actid[0];
    if (ids.indexOf(id) === -1) {
      ids.push(id);
      newHits.push(hit);
    } else {
      const t = newHits.filter((h) => h.fields.actid[0] === id);
      if (t && t.length > 0) {
        t[0].aggregation = t[0].aggregation ? t[0].aggregation + 1 : 1;
      }
    }
  });
  newRes.hit = newHits;
  return newRes;
};

export const searchActions = {
  search: actionCreator<Conditions>('ACTIONS_SEARCH'),
  searchResult: actionCreator<SearchResult>('ACTIONS_SEARCH_RESULT'),
  searchAsync: (c: Conditions, accountid: string, id: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit1 = {
          headers: {}
        };
        const url1 = `/search?accountid=${accountid}&id=${id}&size=${c.rowsPerPage}&start=${c.page * c.rowsPerPage}&mode=${c.mode}`;
        const url2 = '' + (c.words ? `&q=${c.words}` : '') + (c.actnum ? `&actnum=${c.actnum}` : '') + (c.catalog ? `&catalog=${c.catalog}` : '')
          + (c.field ? `&field=${c.field}` : '') + (c.userGroupId ? `&group=${c.userGroupId}` : '');
        const res1 = await API.get(API_NAME, url1 + url2, myInit1);

        const aggRes = aggregationSearchResult(res1);

        dispatch(searchActions.search(c));
        dispatch(searchActions.searchResult(aggRes));
        dispatch(searchActions.setDisplayEstablish(false));

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  setDisplayEstablish: actionCreator<boolean>('ACTIONS_SET_DISPLAY_ESTABLISH'),
  searchEstablishAsync: (c: Conditions, accountid: string, id: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit1 = {
          headers: {}
        };
        const url1 = `/establish?accountid=${accountid}&id=${id}&size=${c.rowsPerPage}&start=${c.page * c.rowsPerPage}&mode=${c.mode}`;
        const url2 = '' + (c.words ? `&q=${c.words}` : '') + (c.actnum ? `&actnum=${c.actnum}` : '') + (c.catalog ? `&catalog=${c.catalog}` : '')
          + (c.field ? `&field=${c.field}` : '') + (c.userGroupId ? `&group=${c.userGroupId}` : '');
        const res1 = await API.get(API_NAME, url1 + url2, myInit1);

        const aggRes = aggregationSearchResult(res1);

        dispatch(searchActions.search(c));
        dispatch(searchActions.searchResult(aggRes));
        dispatch(searchActions.setDisplayEstablish(true));

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  setUndiscloseAsync: (actId: string, undisclose: boolean, c: Conditions, accountid: string, id: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {},
          body: {
            accountid: accountid,
            id: id,
            undisclose: undisclose,
          },
        }
        const url = `/acts/${actId}/disclose`;
        await API.post(API_NAME, url, myInit);

        await searchActions.searchAsync(c, accountid, id)(dispatch);

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  clearEnforcegroups: actionCreator<any>('ACTIONS_CLEAR_ENFORCEGROUPS'),
  getEnforcegroups: actionCreator<any>('ACTIONS_GET_ENFORCEGROUPS'),
  getEnforcegroupsOrg: actionCreator<any>('ACTIONS_GET_ENFORCEGROUPS_ORG'),
  getEnforcegroupsAsync: (actid: string, accountid: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit1 = {
          headers: {}
        };
        const url = `/acts/${actid}/enfrocegroups?accountid=${accountid}`;
        const res = await API.get(API_NAME, url, myInit1);

        dispatch(searchActions.getEnforcegroups(res));
        const newRes = JSON.parse(JSON.stringify(res));
        dispatch(searchActions.getEnforcegroupsOrg(newRes));

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  postEnforcegroupsAsync: (accountid: string, id: string, idid: string, actId: string, editType: string, editingGroups: EditingGroup[]) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {},
          body: {
            accountid: accountid,
            id: id,
            idid: idid,
            editType: editType,
            editingGroups: editingGroups,
          },
        }
        const url = `/acts/${actId}/enfrocegroups`;
        await API.post(API_NAME, url, myInit);
      } catch (e) {
        console.log(e);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  postEnforceCancelAsync: (accountid: string, id: string, idid: string, actId: string, editingIds: string[]) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {},
          body: {
            accountid: accountid,
            id: id,
            idid: idid,
            editingids: editingIds,
          },
        }
        const url = `/acts/${actId}/enforcecancel`;
        await API.post(API_NAME, url, myInit);
      } catch (e) {
        console.log(e);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  postExpireDateAsync: (accountid: string, id: string, idid: string, actId: string, expireDate: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit = {
          headers: {},
          body: {
            accountid: accountid,
            id: id,
            idid: idid,
            expireDate: expireDate,
          },
        }
        const url = `/acts/${actId}/expire`;
        await API.post(API_NAME, url, myInit);
      } catch (e) {
        console.log(e);
      }
      dispatch(appActions.setLoading(false));
    };
  },
  initCatalogs: actionCreator<Catalog[]>('ACTIONS_INIT_CATALOGS'),
  initFields: actionCreator<Field[]>('ACTIONS_INIT_FIELDS'),
};