import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { repealActions } from '../actions/repealAction';
import { Conditions, SearchResult } from './searchState'

export interface RepealState {
    rconditions: Conditions;
    rsearchResultSet?: SearchResult;
};

const initialState: RepealState = {
    rconditions: { mode: 'act', words: '', rowsPerPage: 10, page: 0, userGroupId: '', },
};

export const repealReducer = reducerWithInitialState(initialState)
    .case(repealActions.searchRepealCondition, (state, payload) => {
        return Object.assign({}, state, { rconditions: payload });
    }).case(repealActions.searchRepealResult, (state, payload) => {
        return Object.assign({}, state, { rsearchResultSet: payload });
    });
