import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';

import { Fields } from '../states/searchState';

type Props = {
  open: boolean;
  searchField: Fields | null;
  handleCommit: () => void;
  handleClose: () => void;
};

export const ExpireDialog: React.FC<Props> = (props) => {

  const handleDateChange = () => {
  };

  const getExpireDate = () => {
    if (props.searchField && props.searchField.repealdate && props.searchField.eventtype[0] === '11') {
      return props.searchField.repealdate[0].split('T')[0];
    }
    return '';
  };

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"失効日を設定"}</DialogTitle>
      <DialogContent>
        <TextField
          id={'expiredate'}
          key={'expiredatekey'}
          label={"失効日"}
          type="date"
          value={getExpireDate()}
          onChange={handleDateChange}
          sx={{
            margin: (theme) => theme.spacing(1),
            width: 150,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleCommit} color="primary">
          OK
        </Button>
        <Button onClick={props.handleClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};

