import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { editingAction } from '../actions/editingAction';

export interface EditingState {
    editingActs: EditingActs[];
};
export interface EditingActs {
    id: string,
    editingActTables: EditingAct[];
};
export interface EditingAct {
    editingId: string;
    actId: string;
    title: string;
    newTitle: string;
    editType: string;
    actNum: string;
    editActNum: string;
    editState: string;
    stepId: string;
    updateDate: string;
    rejection: boolean;
};

const initialState: EditingState = {
    editingActs: [{
        id: '',
        editingActTables: [],
    }]
};

export const editingReducer = reducerWithInitialState(initialState)
    .case(editingAction.getEditing, (state, payload) => {
        return Object.assign({}, state, { editingActs: payload });
    });
