import actionCreatorFactory from 'typescript-fsa';
import { Dispatch, Action } from 'redux';
import { API } from 'aws-amplify';

import { AppState } from '../states/appState';
import { appActions } from '../actions/appAction';

export interface CurrentAct {
    actId: string;
    revision: string;
    revisionDate: string;
    informal: boolean;
    title: string;
    actNum: string;
    actState: string;
}

const actionCreator = actionCreatorFactory();

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

export const currentAppAction = {
    getCurrentActTables: actionCreator<CurrentAct[]>('ACTIONS_GET_CURRENT_ACT_TABLES'),
    getCurrentActTablesAsync: () => {
        return async (dispatch: Dispatch<Action>, getState: () => AppState) => {
            try {
                const myInit = {
                    headers: {}
                }
                const res = await API.get(API_NAME, '/acts', myInit);
                const tables = res.map(item => {
                    const result: CurrentAct = {
                        actId: item.actId,
                        revision: item.revision,
                        revisionDate: item.revisionDate,
                        informal: item.informal,
                        title: item.title,
                        actNum: item.actNum,
                        actState: item.actState
                    };
                    return result;
                });
                dispatch(currentAppAction.getCurrentActTables(tables));
            } catch (err) {
                console.log(err);
            }
            dispatch(appActions.setLoading(false));
        };
    }
};
