import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { whatsnewAction } from '../actions/whatsnewAction';

export interface WhatsnewState {
  whatsnews: WhatsnewAct[];
};

export interface WhatsnewAct {
  actId: string;
  accountId: string;
  editingId: string;
  stepIds: string[];
  publicationDate: string;
  title: string;
  actNum: string;
  editType: string;
  editActNum: string;
};

const initialState: WhatsnewState = {
  whatsnews: [],
};

export const whatsnewReducer = reducerWithInitialState(initialState)
  .case(whatsnewAction.getWhatsnew, (state, payload) => {
    return Object.assign({}, state, { whatsnews: payload });
  });
