import React from 'react';

import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';

import { ScrutinyResult } from '../components/Approval';

type Props = {
  open: boolean;
  scrutinyResult: ScrutinyResult[];
  handleClose: () => void;
};

export const ScrutinyResultDialog: React.FC<Props> = (props) => {

  const createItem = (s: ScrutinyResult, i) => {
    return (
      <ListItem key={i}>
        <ListItemIcon>
          <ErrorOutlineIcon color="secondary" />
        </ListItemIcon>
        <ListItemText primary={s.editActNum} secondary={
          <>
            <Typography variant="body1" component="span">{s.error}</Typography>
          </>
        } />
      </ListItem>
    );
  };

  const createContents = () => {
    const items = props.scrutinyResult.map((s, i) => createItem(s, i));
    return (
      <Box sx={{
        width: '100%',
        maxWidth: 360,
        backgroundColor: (theme) => theme.palette.background.paper,
      }}>
        <List component="nav" aria-label="main mailbox folders">
          {items}
        </List>
      </Box>
    );
  };

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title" > {"例規精査結果"} </DialogTitle>
      <DialogContent>
        {createContents()}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary" >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
