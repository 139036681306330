import React from 'react';
import { styled } from '@mui/material/styles'

import { API, Storage } from 'aws-amplify';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import FilterAlt from '@mui/icons-material/FilterAlt';
import ClearAll from '@mui/icons-material/ClearAll';
import Cancel from '@mui/icons-material/Cancel';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Divider from '@mui/material/Divider';
import NoteIcon from '@mui/icons-material/Note';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';

import Drawer from '@mui/material/Drawer';
import LabelIcon from '@mui/icons-material/Label';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import TablePagination from '@mui/material/TablePagination';

import DownloadIcon from '@mui/icons-material/Download';

import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';

import { SearchState } from '../states/searchState';
import { AppState } from '../states/appState';
import { SearchActions } from '../containers/searchContainer';
import { Catalog, Hit, Conditions, Fields } from '../states/searchState';
import { EditTypeDialog } from './EditTypeDialog';
import { SelectRevisionDialog } from './SelectRevisionDialog';
import { RevisionDialog } from './RevisionDialog'
import { ExpireDialog } from './ExpireDialog'
import { RealEnforceDateDialog } from './RealEnforceDateDialog';
import { EditingGroup } from '../states/searchState';

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

const HenResultCatalogListItem = styled(ListItem)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(0),
}));
const SyoResultCatalogListItem = styled(ListItem)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));
const SetuResultCatalogListItem = styled(ListItem)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(4),
}));
const KanResultCatalogListItem = styled(ListItem)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(6),
}));
const MokuResultCatalogListItem = styled(ListItem)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(8),
}));

const HenResultCatalogListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(0),
}));
const SyoResultCatalogListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(2),
}));
const SetuResultCatalogListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(4),
}));
const KanResultCatalogListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(6),
}));
const MokuResultCatalogListItemButton = styled(ListItemButton)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(8),
}));

type SearchProps = {} & SearchState & SearchActions & AppState;

export interface RevisionInfo {
  actid: string;
  revision: number;
  editType?: number;
  revisionDate: string;
  editActNum?: string;
}

interface CheckForm {
  checkedA: boolean;
  checkedB: boolean;
  checkedC: boolean;
}

export const Search: React.FC<SearchProps> = (props) => {

  // ダウンロード
  const handleDownload = async () => {
    try {
      props.setLoading(true);
      const myInit = {
        headers: {},
      };
      const c = props.conditions;
      const url1 = `/search/download?accountid=${props.accountid}&idid=${props.idid}&size=${c.rowsPerPage}&start=${c.page * c.rowsPerPage}&mode=${c.mode}`;
      const url2 = '' + (c.words ? `&q=${c.words}` : '') + (c.actnum ? `&actnum=${c.actnum}` : '') + (c.catalog ? `&catalog=${c.catalog}` : '')
        + (c.field ? `&field=${c.field}` : '') + (c.userGroupId ? `&group=${c.userGroupId}` : '');
      const res = await API.get(API_NAME, url1 + url2, myInit);

      const tmp: string = res.outPath;
      const newOutPath = tmp.replace(/private\/.*?\//, '');

      console.log(newOutPath);
      downloadForm(newOutPath);

    } catch (e) {
      // エラー
      console.log(e);
    }
    props.setLoading(false);
  };

  const downloadForm = async (path: string) => {
    const file = await Storage.get(path, { level: 'private' });
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '' + file);
    xhr.responseType = 'blob';
    xhr.onloadend = function () {
      if (xhr.status !== 200) {
        return false;
      }
      //const blob = new Blob([xhr.response], { "type" : "text/plain" });
      //const blob = new Blob([xhr.response], { "type" : "application/msword" });
      //const blob = new Blob([xhr.response], { "type" : "image/jpeg" });
      const blob = new Blob([xhr.response]);
      const link = document.createElement('a');
      link.download = path;
      link.href = window.URL.createObjectURL(blob);
      link.click();
      //window.navigator.msSaveOrOpenBlob(xhr.response, fileName);
    };
    xhr.send();
  };

  // 検索条件
  const [anchorElDialog, setAnchorElDialog] = React.useState<null | HTMLElement>(null);
  const openDialog = Boolean(anchorElDialog);
  const handleMenuDialog = (event: React.MouseEvent<HTMLElement>) => {
    setWords(props.conditions.words);
    setActnum(props.conditions.actnum ? props.conditions.actnum : '');
    setAnchorElDialog(event.currentTarget);
  };
  const handleCloseDialog = () => {
    setAnchorElDialog(null);
  };
  const handleCOkDialog = () => {
    const c: Conditions = JSON.parse(JSON.stringify(props.conditions));
    c.words = words;
    if (actnum) {
      c.actnum = actnum;
    } else {
      if (c.actnum) delete c.actnum;
    }
    if (catalog) {
      c.catalog = catalog;
    } else {
      if (c.catalog) delete c.catalog;
    }
    if (field) {
      c.field = field;
    } else {
      if (c.field) delete c.field;
    }
    const mode = formC.checkedA ? 'composite' : formC.checkedB ? 'form' : formC.checkedC ? 'title' : 'act';
    c.mode = mode;
    c.userGroupId = props.userGroupId;
    c.page = 0;
    props.setLoading(true);
    props.searchAsync(c, props.accountid, props.id);
    setAnchorElDialog(null);
  };
  const handleClearConditions = () => {
    setWords('');
    setActnum('');
    setCatalog('');
    setField('');
  };

  const [words, setWords] = React.useState('');
  const onChangeWords = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setWords(value);
  };

  const [actnum, setActnum] = React.useState('');
  const onChangeActnum = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    setActnum(value);
  };

  const [catalog, setCatalog] = React.useState('');
  const handleChange = (event) => {
    setCatalog(event.target.value);
  };

  const [formC, setFormC] = React.useState<CheckForm>({
    checkedA: false,
    checkedB: false,
    checkedC: false,
  });

  const handleFormCChange = (event) => {
    const copy: CheckForm = JSON.parse(JSON.stringify(formC));
    if (event.target.name === 'checkedA' && event.target.checked) {
      copy.checkedB = false;
      copy.checkedC = false;
    } else if (event.target.name === 'checkedB' && event.target.checked) {
      copy.checkedA = false;
      copy.checkedC = false;
    } else if (event.target.name === 'checkedC' && event.target.checked) {
      copy.checkedA = false;
      copy.checkedB = false;
    }
    setFormC({ ...copy, [event.target.name]: event.target.checked });
  };

  const createSearchCatalog = () => {
    const items: JSX.Element[] = [];

    const f = (cs: Catalog[], level = 0) => {
      cs.forEach((c) => {
        if (c.id === '9999') return;
        items.push((<MenuItem key={c.id} value={c.id}>{'　'.repeat(level) + c.name}</MenuItem>));
        if (c.child) f(c.child, level + 1);
      });
    };

    f(props.catalogs);
    return items;
  };

  const [field, setField] = React.useState('');
  const handleChange2 = (event) => {
    setField(event.target.value);
  };

  const createSearchField = () => {
    const sf = props.fields.filter((f) => f.id !== '9999');
    const items: JSX.Element[] = sf.map((f) => {
      return (<MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>);
    });
    return items;
  };

  const createSearch = () => {
    const cItems = createSearchCatalog();
    const fItems = createSearchField();
    return (
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"検索条件を指定する"}</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={formC.checkedC}
                onChange={handleFormCChange}
                name="checkedC"
                color="primary"
              />
            }
            label="検索対象を題名のみにする"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formC.checkedA}
                onChange={handleFormCChange}
                name="checkedA"
                color="primary"
              />
            }
            label="検索対象に様式を含める"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={formC.checkedB}
                onChange={handleFormCChange}
                name="checkedB"
                color="primary"
              />
            }
            label="検索対象を様式のみにする"
          /><p />
          <TextField id="words" label="用語" value={words} fullWidth onChange={onChangeWords} /><p />
          <TextField id="actnum" label="番号" value={actnum} onChange={onChangeActnum} /><p />
          <FormControl sx={{
            margin: (theme) => theme.spacing(1),
            minWidth: 120,
          }}>
            <InputLabel id="demo-simple-select-helper-label">体系目次</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="体系目次"
              value={catalog}
              onChange={handleChange}
            >
              <MenuItem key={0} value="">
                <em>None</em>
              </MenuItem>
              {cItems}
            </Select>
          </FormControl><p />
          <FormControl sx={{
            margin: (theme) => theme.spacing(1),
            minWidth: 120,
          }}>
            <InputLabel id="demo-simple-select-helper-label2">分野</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label2"
              id="demo-simple-select-helper2"
              label="分野"
              value={field}
              onChange={handleChange2}
            >
              <MenuItem key={0} value="">
                <em>None</em>
              </MenuItem>
              {fItems}
            </Select>
          </FormControl><p />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCOkDialog} color="secondary" startIcon={<SearchIcon />}>
            検索
          </Button>
          <Button onClick={handleClearConditions} color="primary" startIcon={<ClearAll />}>
            クリア
          </Button>
          <Button onClick={handleCloseDialog} color="primary" startIcon={<Cancel />}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const pageChange = (event, page) => {
    const c: Conditions = JSON.parse(JSON.stringify(props.conditions));
    c.page = page;
    props.setLoading(true);
    if (props.displayEstablish) {
      props.searchEstablishAsync(c, props.accountid, props.id);
    } else {
      props.searchAsync(c, props.accountid, props.id);
    }
  };

  // 施行日適用ダイアログ表示
  const enforceDate = (actid: string) => (event: React.MouseEvent<HTMLElement>) => {
    props.setLoading(true);
    props.getEnforcegroupsAsync(actid, props.accountid);

    handleRealEnforceDateDialog(event);
  };

  const [anchorElRealEnforceDateDialog, setAnchorElRealEnforceDateDialog] = React.useState<null | HTMLElement>(null);
  const openRealEnforceDateDialog = Boolean(anchorElRealEnforceDateDialog);
  const handleRealEnforceDateDialog = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRealEnforceDateDialog(event.currentTarget);
  };
  const handleCloseRealEnforceDateDialog = () => {
    props.clearEnforcegroups('');
    setAnchorElRealEnforceDateDialog(null);
  };
  const handleCommitRealEnforceDateDialog = (editingGroups: EditingGroup[], editType: string) => {
    // commit
    props.setLoading(true);
    props.postEnforcegroups(props.accountid, props.id, props.idid, props.editingGroups[0].enforceGroups[0].actId, editType, editingGroups);
    handleCloseRealEnforceDateDialog();
  };

  // リビジョン操作ダイアログ表示
  const revision = (actid: string) => (event: React.MouseEvent<HTMLElement>) => {
    props.setLoading(true);
    props.getEnforcegroupsAsync(actid, props.accountid);

    handleRevisionDialog(event);
  };

  const [anchorElRevisionDialog, setAnchorElRevisionDialog] = React.useState<null | HTMLElement>(null);
  const openRevisionDialog = Boolean(anchorElRevisionDialog);
  const handleRevisionDialog = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRevisionDialog(event.currentTarget);
  };
  const handleCloseRevisionDialog = () => {
    props.clearEnforcegroups('');
    setAnchorElRevisionDialog(null);
  };
  const handleCommitRevisionDialog = (editingGroups: EditingGroup[]) => {
    // commit
    props.setLoading(true);
    //props.postEnforcegroups(props.accountid, props.id, props.idid, props.editingGroups[0].enforceGroups[0].actId, editType, editingGroups);
    const targetEId = editingGroups[editingGroups.length - 1].editingId;
    props.postEnforceCancelAsync(props.accountid, props.id, props.idid, props.editingGroups[0].enforceGroups[0].actId, [targetEId]);
    handleCloseRevisionDialog();
  };

  // 失効日設定ダイアログ
  const expire = (eField: Fields) => (event: React.MouseEvent<HTMLElement>) => {
    //props.setLoading(true);
    handleExpireDialog(eField)(event);
  };

  const [anchorElExpireDialog, setAnchorElExpireDialog] = React.useState<null | HTMLElement>(null);
  const openExpireDialog = Boolean(anchorElExpireDialog);
  const handleExpireDialog = (eField: Fields) => (event: React.MouseEvent<HTMLElement>) => {
    setExpireField(eField);
    setAnchorElExpireDialog(event.currentTarget);
  };
  const handleCloseExpireDialog = () => {
    setAnchorElExpireDialog(null);
  };
  const handleCommitExpireDialog = () => {
  }

  const [expireField, setExpireField] = React.useState<Fields | null>(null);

  // 体系押したときのイベント
  const handleCatalogClick = (id) => {
    const c: Conditions = JSON.parse(JSON.stringify(props.conditions));
    c.page = 0;
    c.catalog = id;
    c.userGroupId = props.userGroupId;
    props.setLoading(true);
    props.searchAsync(c, props.accountid, props.id);
  };

  const createListItem = (catalogs: Catalog[], items: JSX.Element[], level: number = 0, clsn: boolean | undefined = undefined) => {
    catalogs.forEach((c) => {
      if (c.id === '9999') return;
      const hit = c.id === props.conditions.catalog;
      const className = clsn ? clsn : hit ? true : false;
      items.push(level === 0 ? className ? (
        <HenResultCatalogListItemButton key={c.id} onClick={() => handleCatalogClick(c.id)}>
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary={c.name} sx={{ color: (theme) => theme.palette.primary[700], }} />
        </HenResultCatalogListItemButton>
      ) : (
        <HenResultCatalogListItemButton key={c.id} onClick={() => handleCatalogClick(c.id)}>
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary={c.name} />
        </HenResultCatalogListItemButton>
      ) : level === 1 ? className ? (
        <SyoResultCatalogListItemButton key={c.id} onClick={() => handleCatalogClick(c.id)}>
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary={c.name} sx={{ color: (theme) => theme.palette.primary[700], }} />
        </SyoResultCatalogListItemButton>
      ) : (
        <SyoResultCatalogListItemButton key={c.id} onClick={() => handleCatalogClick(c.id)}>
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary={c.name} />
        </SyoResultCatalogListItemButton>
      ) : level === 2 ? className ? (
        <SetuResultCatalogListItemButton key={c.id} onClick={() => handleCatalogClick(c.id)}>
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary={c.name} sx={{ color: (theme) => theme.palette.primary[700], }} />
        </SetuResultCatalogListItemButton>
      ) : (
        <SetuResultCatalogListItemButton key={c.id} onClick={() => handleCatalogClick(c.id)}>
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary={c.name} />
        </SetuResultCatalogListItemButton>
      ) : level === 4 ? className ? (
        <KanResultCatalogListItemButton key={c.id} onClick={() => handleCatalogClick(c.id)}>
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary={c.name} sx={{ color: (theme) => theme.palette.primary[700], }} />
        </KanResultCatalogListItemButton>
      ) : (
        <KanResultCatalogListItemButton key={c.id} onClick={() => handleCatalogClick(c.id)}>
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary={c.name} />
        </KanResultCatalogListItemButton>
      ) : className ? (
        <MokuResultCatalogListItemButton key={c.id} onClick={() => handleCatalogClick(c.id)}>
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary={c.name} sx={{ color: (theme) => theme.palette.primary[700], }} />
        </MokuResultCatalogListItemButton>
      ) : (
        <MokuResultCatalogListItemButton key={c.id} onClick={() => handleCatalogClick(c.id)}>
          <ListItemIcon><LabelIcon /></ListItemIcon>
          <ListItemText primary={c.name} />
        </MokuResultCatalogListItemButton>
      ));
      if (c.child) {
        createListItem(c.child, items, level + 1, className);
      }
    });
    return items;
  };

  const handleEstablish = () => {
    handleClearConditions();
    const c: Conditions = JSON.parse(JSON.stringify(props.conditions));
    c.words = words;
    if (actnum) {
      c.actnum = actnum;
    } else {
      if (c.actnum) delete c.actnum;
    }
    if (catalog) {
      c.catalog = catalog;
    } else {
      if (c.catalog) delete c.catalog;
    }
    if (field) {
      c.field = field;
    } else {
      if (c.field) delete c.field;
    }
    const mode = formC.checkedA ? 'composite' : formC.checkedB ? 'form' : 'act';
    c.mode = mode;
    c.userGroupId = props.userGroupId;
    c.page = 0;
    props.setLoading(true);
    props.searchEstablishAsync(c, props.accountid, props.id);
  };

  const createEstablish = () => {
    return props.displayEstablish ? (<HenResultCatalogListItemButton key={'es'} onClick={() => handleEstablish()}>
      <ListItemIcon><LabelIcon /></ListItemIcon>
      <ListItemText primary={'新規制定・全部改正される予定の文書'} sx={{ color: (theme) => theme.palette.primary[700], }} />
    </HenResultCatalogListItemButton>) : (<HenResultCatalogListItemButton key={'es'} onClick={() => handleEstablish()}>
      <ListItemIcon><LabelIcon /></ListItemIcon>
      <ListItemText primary={'新規制定・全部改正される予定の文書'} />
    </HenResultCatalogListItemButton>);
  }

  const createTreeList = () => {
    return (
      <List>
        {createEstablish()}
        {createListItem(props.catalogs, [])}
      </List>
    );
  };

  type CatalogSet = {
    catalogs: string[];
    searchResult: Hit[];
  };
  type CatalogValue = {
    name: string;
    level: number;
  };

  const getCatalogName = (id, catalogs: Catalog[]): CatalogValue => {
    const f = (id, catalogs: Catalog[], level = 0) => {
      let r = { name: '', level: 0 };
      catalogs.forEach((c) => {
        if (id === c.id) {
          r.name = c.name;
          r.level = level;
        } else if (c.child) {
          const t = f(id, c.child, level + 1);
          r = t.name ? t : r;
        }
      });
      return r;
    };
    return f(id, catalogs);
  };

  const getFieldName = (id) => {
    const hit = props.fields.find((v) => v.id === id);
    if (hit) return hit.name;
    return '';
  };
  const getFieldNames = (ids: string[]) => {
    const fs = ids.map(i => {
      return getFieldName(i);
    });
    return fs.join('、');
  };

  // リビジョン選択ダイアログ
  const [selectRevisionDialogProps, setSelectRevisionDialogProps] = React.useState<RevisionInfo[]>([]);
  const [anchorElSelectRevisionDialog, setAnchorElSelectRevisionDialog] = React.useState(false);
  const openPossiblesDialog = Boolean(anchorElSelectRevisionDialog);
  const handleSelectRevisionDialog = (event: React.ChangeEvent<HTMLInputElement>) => {
    const r = (event.target as HTMLInputElement).value;
    const rnum = Number.parseInt(r);
    if (selectRevisionDialogProps) {
      const selected = selectRevisionDialogProps.find((o) => o.revision === rnum);
      if (selected) {
        setSelectedRevisionAct(selected);
      }
    }
  };
  const handleClosePossiblesDialog = () => setAnchorElSelectRevisionDialog(false);
  const handleOkSelectRevisionDialog = () => {
    if (selectedRevisionAct) {
      handleClosePossiblesDialog();
      handleEditTypeDialog();
    }
  };

  const [selectedRevisionAct, setSelectedRevisionAct] = React.useState<RevisionInfo>({ actid: 'test', revision: -1, revisionDate: '' });

  // 編集画面
  const [openEditTypeDialog, setOpenEditTypeDialog] = React.useState(false);
  const handleOpenEditTypeDialog = () => setOpenEditTypeDialog(true);
  const handleCloseEditTypeDialog = () => setOpenEditTypeDialog(false);
  const handleEditTypeDialog = () => {
    handleCloseEditTypeDialog();
    setEditTypeList(props.editTypeList);
    setEditTypeValue('1');// 一部改正が選択された状態でダイアログOPEN
    handleOpenEditTypeDialog();
  };
  const [editTypeList, setEditTypeList] = React.useState<any>(null);
  const [editTypeValue, setEditTypeValue] = React.useState<string>('');
  const handleEditTypeRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditTypeValue((event.target as HTMLInputElement).value);
  };
  const okEditTypeDialog = () => {
    if (selectedRevisionAct) {
      const pureActId = selectedRevisionAct.actid.substring(selectedRevisionAct.actid.indexOf('#') + 1);
      const query = `?actId=${pureActId}&revision=${selectedRevisionAct.revision}&editType=${editTypeValue}`;
      const url = process.env.REACT_APP_EDITOR_URL ? process.env.REACT_APP_EDITOR_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_EDITOR_URL;
      window.open(url + query, 'editor');
    } else {
      console.error('selectedAct null');
    }
    handleCloseEditTypeDialog();
  };

  const actEdit = async (actid) => {
    const myInit = {
      headers: {},
    };
    const url = `/acts/${actid}/revisions?accountid=${props.accountid}`;
    const res: RevisionInfo[] = await API.get(API_NAME, url, myInit);

    setSelectRevisionDialogProps(res);
    setSelectedRevisionAct(res[res.length - 1]);
    setAnchorElSelectRevisionDialog(true);
  };

  const viewEnforceDateIcon = (actid, f) => {
    return ['Manager', 'Approver'].indexOf(props.role) > -1 && f ? (
      <Tooltip title="本施行日を設定する">
        <IconButton sx={{
          color: (theme) => theme.palette.secondary.main,
          margin: 1,
        }} edge="end" aria-label="enforcedate" onClick={enforceDate(actid)}>
          <CalendarTodayIcon />
        </IconButton>
      </Tooltip>
    ) : '';
  };

  const viewRevisionIcon = (actid, f) => {
    return f ? (
      <Tooltip title="改正履歴">
        <IconButton sx={{
          color: (theme) => theme.palette.secondary.main,
          margin: 1,
        }} edge="end" aria-label="revision" onClick={revision(actid)}>
          <ManageHistoryIcon />
        </IconButton>
      </Tooltip>
    ) : '';
  }

  const viewExpireIcon = (field) => {
    return ['Manager', 'Approver'].indexOf(props.role) > -1 ? (
      <Tooltip title="失効日設定">
        <IconButton sx={{
          color: (theme) => theme.palette.secondary.main,
          margin: 1,
        }} edge="end" aria-label="revision" onClick={expire(field)}>
          <AlarmOnIcon />
        </IconButton>
      </Tooltip>
    ) : '';
  }

  const viewEditorIcon = (actid) => {
    return ['Manager', 'Approver', 'Editor'].indexOf(props.role) > -1 ? (
      <Tooltip title="編集を開始する">
        <IconButton sx={{
          color: (theme) => theme.palette.secondary.main,
          margin: 1,
        }} edge="end" aria-label="edit" onClick={() => actEdit(actid)}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    ) : '';
  };

  const handleUndisclose = (actid, flag: boolean) => (event: React.MouseEvent<HTMLElement>) => {
    props.setLoading(true);

    const c: Conditions = JSON.parse(JSON.stringify(props.conditions));
    props.setUndiscloseAsync(actid, !flag, c, props.accountid, props.id);
    event.stopPropagation();
  };
  const createDiscloseIcon = (actid, flag: boolean) => {
    return ['Manager', 'Approver'].indexOf(props.role) > -1 ? flag ? (
      <Tooltip title="外部公開にする（外部非公開中）">
        <IconButton sx={{
          color: (theme) => theme.palette.secondary.main,
          margin: 1,
        }} edge="end" aria-label="undisclose" onClick={handleUndisclose(actid, flag)}>
          <PublicOffIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title="外部非公開にする（外部公開中）">
        <IconButton sx={{
          color: (theme) => theme.palette.secondary.main,
          margin: 1,
        }} edge="end" aria-label="undisclose" onClick={handleUndisclose(actid, flag)}>
          <PublicIcon />
        </IconButton>
      </Tooltip>
    ) : '';
  };

  const createResult = () => {
    const viewcset: Array<CatalogSet> = [];
    if (props.searchResultSet) {
      const catalogs: string[] = [];
      props.searchResultSet.hit.forEach((r) => {

        const newc = r.fields.catalog.concat();
        const b = newc.some((c, i) => {
          if (!catalogs.includes(c)) {
            const viewc: CatalogSet = {
              catalogs: [],
              searchResult: [],
            };
            if (i === 0) {
              viewc.catalogs = newc.splice(0, newc.length);
            } else {
              viewc.catalogs = newc.splice(i, newc.length);
            }
            catalogs.push(...viewc.catalogs);
            viewc.searchResult.push(r);
            viewcset.push(viewc);
            return true;
          }
          return false;
        });

        if (!b) {
          const viewc = viewcset.pop();
          if (viewc) {
            viewc.searchResult.push(r);
            viewcset.push(viewc);
          }
        }
      });
    }

    const actView = (actId, revision) => {
      const query = `?type=${'revision'}&actId=${actId}&miekesi=false`;
      const url = process.env.REACT_APP_VIEWER_URL ? process.env.REACT_APP_VIEWER_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_VIEWER_URL;
      window.open(url + query, `viewer_${actId}_${revision}`);
    };

    const view: JSX.Element[] = [];
    viewcset.forEach((vs, index) => {
      const cs: JSX.Element[] = vs.catalogs.map((c, ic) => {
        const cv = getCatalogName(c, props.catalogs);
        return cv.level === 0 ? (
          <HenResultCatalogListItem key={`catalog-${index}-${ic}`}>
            <ListItemIcon><LabelIcon /></ListItemIcon>
            <ListItemText primary={cv.name} />
          </HenResultCatalogListItem>
        ) : cv.level === 1 ? (
          <SyoResultCatalogListItem key={`catalog-${index}-${ic}`}>
            <ListItemIcon><LabelIcon /></ListItemIcon>
            <ListItemText primary={cv.name} />
          </SyoResultCatalogListItem>
        ) : cv.level === 2 ? (
          <SetuResultCatalogListItem key={`catalog-${index}-${ic}`}>
            <ListItemIcon><LabelIcon /></ListItemIcon>
            <ListItemText primary={cv.name} />
          </SetuResultCatalogListItem>
        ) : cv.level === 4 ? (
          <KanResultCatalogListItem key={`catalog-${index}-${ic}`}>
            <ListItemIcon><LabelIcon /></ListItemIcon>
            <ListItemText primary={cv.name} />
          </KanResultCatalogListItem>
        ) : (
          <MokuResultCatalogListItem key={`catalog-${index}-${ic}`}>
            <ListItemIcon><LabelIcon /></ListItemIcon>
            <ListItemText primary={cv.name} />
          </MokuResultCatalogListItem>
        );
      });
      const createAction = (r: Hit) => {
        return r.fields.editicondisp[0] ? (
          <ListItemSecondaryAction>
            {viewEnforceDateIcon(r.fields.actid, r.fields.enforcedayicondisp[0])}
            {viewRevisionIcon(r.fields.actid, r.fields.historyicondisp[0])}
            {/* {viewExpireIcon(r.fields)} */}
            {viewEditorIcon(r.fields.actid)}
          </ListItemSecondaryAction>
        ) : '';
      };
      const rs: JSX.Element[] = vs.searchResult.map((r, ir) => {
        return (
          <div key={`result-${index}-${ir}`}>
            <ListItem button onClick={() => actView(r.fields.actid, r.fields.revision)}>
              <ListItemIcon sx={{
                color: (theme) => theme.palette.primary.main,
                margin: 1,
              }}>
                <NoteIcon />
              </ListItemIcon>
              <ListItemText primary={
                <>
                  <Typography variant="body1" component="span" sx={{
                    display: 'inline-block',
                    width: '70%',
                    verticalAlign: 'middle',
                  }} dangerouslySetInnerHTML={{
                    __html: r.highlights.title ? r.highlights.title : ''
                  }}>
                  </Typography>
                  {createDiscloseIcon(r.fields.actid, r.fields.undisclose[0])}
                </>
              } secondary={
                <>
                  <Typography variant="subtitle1" component="span">番号：</Typography>
                  <Typography variant="body1" component="span">{r.fields.actnum}</Typography>
                  <br />
                  <Typography variant="subtitle1" component="span">分野：</Typography>
                  <Typography variant="body1" component="span">{getFieldNames(r.fields.field)}</Typography>
                  <br />
                  <Typography variant="subtitle1" component="span">プレビュー：</Typography>
                  <Typography variant="body1" component="span" sx={{
                    display: 'inline-block',
                    width: '70%',
                    verticalAlign: 'middle',
                  }} dangerouslySetInnerHTML={{
                    __html: r.highlights.document ? r.highlights.document : r.highlights.form ? r.highlights.form : ''
                  }}>
                  </Typography>
                </>
              } />
              {createAction(r)}
            </ListItem>
            <Divider />
          </div>
        );
      });
      view.push((
        <div key={index}>
          <Paper sx={{
            minWidth: 275,
            margin: 1,
          }} elevation={3}>
            <List>
              {cs}
            </List>
          </Paper>
          <Paper sx={{
            minWidth: 275,
            margin: 1,
            padding: 1,
            backgroundColor: (theme) => theme.palette.primary[50],
          }} elevation={3}>
            <List component="nav" aria-label="My editing acts">
              {rs}
            </List>
          </Paper>
        </div>
      ));
    });

    return (
      <>
        {view}
      </>
    );
  };

  const createDisplayConditions = () => {
    const view: JSX.Element[] = [];
    if (props.conditions.mode) {
      const v = props.conditions.mode === 'act'
        ? (<Typography key="mode" variant="body1" component="span">検索対象 : [ {'本文'} ]&nbsp;</Typography>)
        : props.conditions.mode === 'form'
          ? (<Typography key="mode" variant="body1" component="span">検索対象 : [ {'様式のみ'} ]&nbsp;</Typography>)
          : props.conditions.mode === 'title'
            ? (<Typography key="mode" variant="body1" component="span">検索対象 : [ {'題名のみ'} ]&nbsp;</Typography>)
            : (<Typography key="mode" variant="body1" component="span">検索対象 : [ {'本文と様式'} ]&nbsp;</Typography>);
      view.push(v);
    }
    if (props.conditions.words)
      view.push(<Typography key="words" variant="body1" component="span">用語 : [ {props.conditions.words} ]&nbsp;</Typography>);
    if (props.conditions.actnum)
      view.push(<Typography key="actnum" variant="body1" component="span">例規番号 : [ {props.conditions.actnum} ]&nbsp;</Typography>);
    if (props.conditions.catalog)
      view.push(<Typography key="catalog" variant="body1" component="span">体系目次: [ {getCatalogName(props.conditions.catalog, props.catalogs).name} ]&nbsp;</Typography>);
    if (props.conditions.field)
      view.push(<Typography key="field" variant="body1" component="span">分野: [ {getFieldName(props.conditions.field)} ]&nbsp;</Typography>)

    return (
      <>
        {view}
      </>
    );
  };

  const defaultLabelDisplayedRows = ({ from, to, count }) => {
    return `${count !== -1 ? count : `more than ${to}`}件中 ${from}–${to}件目`;
  };

  return (
    <Box sx={{
      display: 'flex',
    }}>
      <Drawer
        sx={{
          width: 270,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            backgroundColor: (theme) => theme.palette.primary[50],
            width: 270,
          },
        }}
        variant="permanent"
      >
        <Box sx={(theme) => theme.mixins.toolbar} />
        {createTreeList()}
      </Drawer>
      <Box sx={{
        flexGrow: 1,
        padding: (theme) => theme.spacing(3),
      }}>
        {createSearch()}
        <Typography sx={{ margin: 2 }} variant="h5" gutterBottom>
          <SearchIcon fontSize='large' color='primary' sx={{ marginBottom: 1, marginRight: 1, verticalAlign: 'middle' }} />
          文書を検索・編集する
        </Typography>
        <Paper sx={{
          minWidth: 275,
          margin: 1,
        }} elevation={3}>
          <Tooltip title="検索条件を指定する">
            <IconButton aria-label="search" onClick={handleMenuDialog}>
              <FilterAlt sx={{
                color: (theme) => theme.palette.secondary.main,
                margin: 1,
              }} fontSize="large" />
              <Typography variant="subtitle1" component="span">検索条件：</Typography>
              {createDisplayConditions()}
            </IconButton>
          </Tooltip>
          <Tooltip title="検索結果をダウンロードする">
            <IconButton aria-label="search" onClick={handleDownload}>
              <DownloadIcon sx={{
                color: (theme) => theme.palette.secondary.main,
                margin: 1,
                align: 'right',
              }} fontSize="large" />
            </IconButton>
          </Tooltip>
        </Paper>
        {createResult()}
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={props.searchResultSet ? props.searchResultSet.found : 0}
          rowsPerPage={props.conditions.rowsPerPage}
          page={props.conditions.page}
          onPageChange={pageChange}
          labelDisplayedRows={defaultLabelDisplayedRows}
        /><br /><br />
      </Box>
      <EditTypeDialog
        open={openEditTypeDialog}
        editTypeValue={editTypeValue}
        editTypeList={editTypeList}
        handleRadioChange={handleEditTypeRadioChange}
        handleClose={handleCloseEditTypeDialog}
        okEditTypeDialog={okEditTypeDialog}
      />
      <SelectRevisionDialog
        open={openPossiblesDialog}
        value={selectedRevisionAct}
        list={selectRevisionDialogProps}
        handleRadioChange={handleSelectRevisionDialog}
        handleClose={handleClosePossiblesDialog}
        okDialog={handleOkSelectRevisionDialog}
      />
      <RevisionDialog
        open={openRevisionDialog}
        accountid={props.accountid}
        role={props.role}
        editingGroups={props.editingGroups}
        editingGroupsOrg={props.editingGroupsOrg}
        getEnforcegroups={props.getEnforcegroups}
        handleCommit={handleCommitRevisionDialog}
        handleClose={handleCloseRevisionDialog}
      />
      <RealEnforceDateDialog
        open={openRealEnforceDateDialog}
        editingGroups={props.editingGroups}
        editingGroupsOrg={props.editingGroupsOrg}
        getEnforcegroups={props.getEnforcegroups}
        handleCommit={handleCommitRealEnforceDateDialog}
        handleClose={handleCloseRealEnforceDateDialog}
      />
      <ExpireDialog
        open={openExpireDialog}
        searchField={expireField}
        handleCommit={handleCommitExpireDialog}
        handleClose={handleCloseExpireDialog}
      />
    </Box >
  );
};