import actionCreatorFactory from 'typescript-fsa';
import { Dispatch, Action } from 'redux';
import { API } from 'aws-amplify';

import { ActInfo, ActType, ApprovalActs, EditActInfo, EnforceInfo } from '../states/approvalState';
import { appActions } from '../actions/appAction';
import { EditStateAPIParam } from './editingActsAction';

const actionCreator = actionCreatorFactory();

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

export const approvalAction = {
	initActtype: actionCreator<ActType[]>('ACTION_INIT_ACTTYPE'),
	setApprovalError: actionCreator<boolean>('ACTION_SET_APPROVAL_ERROR'),
	getApprovalActs: actionCreator<ApprovalActs[]>('ACTIONS_GET_APPROVAL_ACTS'),
	getApprovalActsAsync: (accountid: string, id: string) => {
		return async (dispatch: Dispatch<Action>) => {
			try {
				const myInit = {
					headers: {}
				}
				const res = await API.get(API_NAME, `/editing?accountid=${accountid}&id=${id}&state=done`, myInit);

				const approvalActs: ApprovalActs[] = res.map((a,) => {
					return {
						id: a.id,
						approvalActTables: (() => {
							return a.editingActs.map((item) => {
								return {
									actId: item.actId,
									editingId: item.editingId,
									actNum: item.actNum,
									editActNum: item.editActNum,
									title: item.title,
									newTitle: item.newTitle,
									editState: item.editState,
									editType: item.editType,
									updateDate: item.updateDate,
									stepId: item.stepId,
									editActInfo: item.editActInfo || {
										publicationDate: '',
										editActType: '',
										editActNum: '',
										editActNumOptionViewFlag: false
									},
									actInfo: item.actInfo || {
										publicationDate: '',
										actType: '',
										actNum: '',
										actNumOptionViewFlag: false,
										expireDate: '',
										beforeEnactment: '',
										afterEnactment: '',
										preamble: '',
									},
									enforceInfo: item.enforceInfo,
									stepIdInfo: item.stepIdInfo || [1],
								};
							});
						})(),
					};
				});
				dispatch(approvalAction.getApprovalActs(approvalActs));

				const myInit3 = {
					headers: {}
				};
				const url3 = `/settings/${accountid}/acttype`;
				const res3 = await API.get(API_NAME, url3, myInit3);
				dispatch(approvalAction.initActtype(res3.actType));
			} catch (err) {
				console.log(err);
			}
			dispatch(appActions.setLoading(false));
		};
	},
	approvalAsync: (accountid: string, editingId: string, actId: string, idid: string, id: string, editType: string) => {
		return async (dispatch: Dispatch<Action>) => {
			try {
				const myInit = {
					headers: {},
					body: {
						accountid: accountid,
						idid: idid,
						id: id,
						editType: editType,
					},
				};
				const url = `/acts/${actId}/editings/${editingId}/enforce`;
				await API.put(API_NAME, url, myInit);

				await approvalAction.getApprovalActsAsync(accountid, id)(dispatch);

			} catch (err: any) {
				if (err.response && err.response.data) {
					const errorData = JSON.parse(JSON.stringify(err.response.data));
					if (errorData.type === 'ExclusiveLockError') {
						dispatch(approvalAction.setApprovalError(true));
					}
				} else {
					console.log(err);
				}
			}
			dispatch(appActions.setLoading(false));
		};
	},
	scrutinyAsync: (accountid: string, editingId: string, actId: string, idid: string, id: string, editType: string) => {
		return async (dispatch: Dispatch<Action>) => {
			try {
				const myInit = {
					headers: {},
					body: {
						accountid: accountid,
						idid: idid,
						id: id,
						editType: editType,
					},
				};
				const url = `/acts/${actId}/editings/${editingId}/enforce/scrutiny`;
				await API.put(API_NAME, url, myInit);

				await approvalAction.getApprovalActsAsync(accountid, id)(dispatch);

			} catch (err: any) {
				if (err.response && err.response.data) {
					const errorData = JSON.parse(JSON.stringify(err.response.data));
					if (errorData.type === 'ExclusiveLockError') {
						dispatch(approvalAction.setApprovalError(true));
					}
				} else {
					console.log(err);
				}
			}
			dispatch(appActions.setLoading(false));
		};
	},
	putEditStateAsync: (param: EditStateAPIParam, rejection: boolean) => {
		return async (dispatch: Dispatch<Action>) => {
			try {
				// 編集状態更新
				const myInit = {
					headers: {},
					body: {
						accountid: param.accountId,
						id: param.id,
						editState: param.editState,
						editType: param.editType,
						rejection: rejection,
					},
				}
				const url = `/acts/${param.actId}/editings/${param.editingId}/editstate`;
				await API.put(API_NAME, url, myInit);
				// 再取得
				await approvalAction.getApprovalActsAsync(param.accountId, param.id)(dispatch);
			} catch (err) {
				console.log(err);
			}
			dispatch(appActions.setLoading(false));
		}
	},
	commitEditActInfoAsync: (param: EditStateAPIParam, detail: EditActInfo) => {
		return async (dispatch: Dispatch<Action>) => {
			try {
				const myInit = {
					headers: {},
					body: {
						accountid: param.accountId,
						id: param.id,
						editActInfo: detail,
					},
				};
				const url = `/acts/${param.actId}/editings/${param.editingId}/editactinfo`;
				await API.put(API_NAME, url, myInit);

				// 再取得
				await approvalAction.getApprovalActsAsync(param.accountId, param.id)(dispatch);
			} catch (err) {
				console.log(err);
			}
			dispatch(appActions.setLoading(false));
		}
	},
	commitActInfoAsync: (param: EditStateAPIParam, detail: ActInfo) => {
		return async (dispatch: Dispatch<Action>) => {
			try {
				const myInit = {
					headers: {},
					body: {
						accountid: param.accountId,
						id: param.id,
						currentActInfo: detail,
					},
				};
				const url = `/acts/${param.actId}/editings/${param.editingId}/currentactinfo`;
				await API.put(API_NAME, url, myInit);

				// 再取得
				await approvalAction.getApprovalActsAsync(param.accountId, param.id)(dispatch);
			} catch (err) {
				console.log(err);
			}
			dispatch(appActions.setLoading(false));
		}
	},
	commitEnforceInfoAsync: (param: EditStateAPIParam, detail: EnforceInfo) => {
		return async (dispatch: Dispatch<Action>) => {
			try {
				const myInit = {
					headers: {},
					body: {
						accountid: param.accountId,
						id: param.id,
						enforceInfo: detail,
					},
				};
				// 呼び出しはstep1固定。enforceInfoに全段含まれている
				const url = `/acts/${param.actId}/editings/${param.editingId}/steps/${1}/enforceinfo`;
				await API.put(API_NAME, url, myInit);

				// 再取得
				await approvalAction.getApprovalActsAsync(param.accountId, param.id)(dispatch);
			} catch (err) {
				console.log(err);
			}
			dispatch(appActions.setLoading(false));
		}
	},
};
