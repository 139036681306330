import { connect } from 'react-redux';

import { informationActions } from '../actions/informationAction';
import { whatsnewAction } from '../actions/whatsnewAction';
import { editingAction } from '../actions/editingAction';
import { appActions } from '../actions/appAction';
import { Main } from '../components/Main';

export interface MainActions {
  getInformationsAsync: (accountId: string, id:string) => void,
  getWhatsnewAsync: (accountId: string, id:string) => void,
  getMyEditingAsync: (accountId: string, id) => void,
  getEditingAsync: (accountId: string, id) => void,
  setLoading: (loading: boolean) => void;
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    getInformationsAsync: (accountId: string, id:string) => dispatch(informationActions.getInformationsAsync(accountId, id)),
    getWhatsnewAsync: (accountId: string, id:string) => dispatch(whatsnewAction.getWhatsnewAsync(accountId, id)),
    getMyEditingAsync: (accountId: string, id) => dispatch(editingAction.getMyEditingAsync(accountId, id)),
    getEditingAsync: (accountId: string, id) => dispatch(editingAction.getEditingAsync(accountId, id)),
    setLoading: (loading: boolean) => dispatch(appActions.setLoading(loading)),
  };
};

const mapStateToProps = (state, ownProps) => {
  return Object.assign({}, state.appState, state.myEditingState, state.whatsnewActsState, state.informationsState);
};

export default connect(mapStateToProps, mapDispatchToProps)(Main);
