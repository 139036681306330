import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { searchActions } from '../actions/searchAction';

export interface SearchState {
    conditions: Conditions;
    catalogs: Catalog[];
    fields: Field[];
    searchResultSet?: SearchResult;
    editingGroups: EditingGroup[];
    editingGroupsOrg: EditingGroup[];
    displayEstablish: boolean;
};

export interface Conditions {
    mode: string;
    words: string;
    actnum?: string;
    catalog?: string;
    field?: string;
    rowsPerPage: number;
    page: number;
    userGroupId: string;
};

export interface Catalog {
    id: string;
    name: string;
    child?: Catalog[];
};

export interface Field {
    id: string;
    name: string;
};

export interface SearchResult {
    found: number;
    start: number;
    hit: Hit[];
};

export interface Hit {
    id: string;
    fields: Fields;
    highlights: Highlights;
    aggregation?: number;
};

export interface Highlights {
    title?:string;
    document?: string;
    form?: string;
};

export interface Fields {
    accountid: string[];
    actid: string[];
    actnum: string[];
    acttype: string[];
    catalog: string[];
    createdate: string[];
    createuser: string[];
    crossing: string[];
    enforcedate: string[];
    eventdate: string[];
    eventtype: string[];
    field: string[];
    group: string[];
    editicondisp: boolean[];
    latest: string[];
    publicationdate: string[];
    repealdate: string[];
    revision: string[];
    status: string[];
    title: string[];
    volume: string[];
    enforcedayicondisp: boolean[];
    historyicondisp: boolean[];
    undisclose: boolean[];
};

export interface EditingGroup {
    editingId: string;
    delete?: boolean;
    enforceGroups: EnforceGroup[];
};

export interface EnforceGroup {
    accountId: string;
    actId: string;
    editingId: string;
    revision: number;
    groupId: string;
    editActNum: string;
    editType: number;
    publicationDate: string;
    enforceDate: string;
    informal: boolean;
    changed?: boolean;
    enforceDateOrg?: string;
    informalOrg?: boolean;
};

const initialState: SearchState = {
    conditions: { mode: 'act', words: '', rowsPerPage: 10, page: 0, userGroupId: '', },
    catalogs: [],
    fields: [],
    editingGroups: [],
    editingGroupsOrg: [],
    displayEstablish: false,
};

export const searchReducer = reducerWithInitialState(initialState)
    .case(searchActions.search, (state, payload) => {
        return Object.assign({}, state, { conditions: payload });
    }).case(searchActions.searchResult, (state, payload) => {
        return Object.assign({}, state, { searchResultSet: payload });
    }).case(searchActions.initCatalogs, (state, payload) => {
        return Object.assign({}, state, { catalogs: payload });
    }).case(searchActions.initFields, (state, payload) => {
        return Object.assign({}, state, { fields: payload });
    }).case(searchActions.getEnforcegroups, (state, payload) => {
        return Object.assign({}, state, { ...payload });
    }).case(searchActions.getEnforcegroupsOrg, (state, payload) => {
        return Object.assign({}, state, { editingGroupsOrg: payload.editingGroups });
    }).case(searchActions.clearEnforcegroups, (state, payload) => {
        return Object.assign({}, state, {
            editingGroups: [],
            editingGroupsOrg: [],
        });
    }).case(searchActions.setDisplayEstablish, (state, payload) => {
        return Object.assign({}, state, { displayEstablish: payload });
    });
