import React from 'react';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

type SearchProps = {};

export const TagsManagement: React.FC<SearchProps> = (props) => {

  return (
    <>
      <Typography sx={{ margin: 1, }} variant="h4" gutterBottom>
        体系・分野管理
      </Typography>
      <Paper sx={{
        minWidth: 275,
        width: '95%',
        margin: 2,
        padding: 1,
        backgroundColor: (theme) => theme.palette.primary[50],
      }} elevation={3}>
        <Typography variant="h5">
          体系目次
        </Typography>
        <div>
          <List sx={{ '-khtml-user-drag': 'element', }}>
            <div draggable="true">
              <ListItem button>
                <ListItemText primary={'a'} />
              </ListItem>
            </div>
            <div draggable="true">
              <ListItem button>
                <ListItemText primary={'b'} />
              </ListItem>
            </div>
            <div draggable="true">
              <ListItem button>
                <ListItemText primary={'c'} />
              </ListItem>
            </div>
          </List>
        </div>
      </Paper>
    </>
  );
};