import React from 'react';
import { API } from 'aws-amplify';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import NoteIcon from '@mui/icons-material/Note';
import InfoIcon from '@mui/icons-material/Info';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import OutputIcon from '@mui/icons-material/Output';

import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import WarningIcon from '@mui/icons-material/Warning';
import ApprovalIcon from '@mui/icons-material/Approval';
import ReplayIcon from '@mui/icons-material/Replay';

import { AppState } from '../states/appState';
import { ActInfo, ApprovalAct, ApprovalActs, ApprovalState, EditActInfo, EnforceInfo, EnforceGroup, ChangeGroup } from '../states/approvalState';
import { ApprovalActions } from '../containers/approvalConteiner';
import { EditStateAPIParam } from '../actions/editingActsAction';
import { EnforceDateDialog } from './EnforceDateDialog';
import { EditActInfoDialog } from './EditActInfoDialog';
import { ActInfoDialog } from './ActInfoDialog';
import { DocumentCreateDialog } from './DocumentCreateDialog';
import { ScrutinyResultDialog } from './ScrutinyResultDialog';

type ApprovalProps = {} & AppState & ApprovalState & ApprovalActions;

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

const editingView = (editingId: string, actId: string, stepId: string, editType: string) => {
  if (['80', '81'].indexOf(editType) === -1) {
    const query = `?type=${'editor'}&actId=${actId}&editingId=${editingId}&stepId=${stepId}&editType=${editType}&miekesi=true`;
    const url = process.env.REACT_APP_VIEWER_URL ? process.env.REACT_APP_VIEWER_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_VIEWER_URL;
    window.open(url + query, `viewer_${actId}_${editingId}_${stepId}`);
  } else {
    const query = `?type=${'revision'}&actId=${actId}&miekesi=false`;
    const url = process.env.REACT_APP_VIEWER_URL ? process.env.REACT_APP_VIEWER_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_VIEWER_URL;
    window.open(url + query, `viewer_${actId}`);
  }
};

const s_EDIT_TYPE = {
  '0': '新規制定',
  '1': '一部改正',
  '2': '全部改正',
  '3': '廃止',
  '80': '履歴管理',
  '81': '施行日設定',
};

interface SelectedAct {
  actId: string;
  editingId: string;
  editType: string;
};

export interface ScrutinyResult {
  actId: string;
  editingId: string;
  revision: number;
  revisionDate: string;
  editActNum: string;
  error: string;
};

export interface DownloadDocument {
  allact?: string;
  revision?: string;
  newold?: string;
  publication?: string;
}
const initialDownloadDocument = () => {
  return {};
};

export const Approval: React.FC<ApprovalProps> = (props) => {

  const [selectedAct, setSelectedAct] = React.useState<null | SelectedAct>(null);

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const handleOpenDialog = (editingId, actId, editType) => {
    setSelectedAct({ actId: actId, editingId: editingId, editType: editType });
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleApprovalOk = () => {
    if (selectedAct) {
      props.setLoading(true);
      props.approval(props.accountid, selectedAct.editingId, selectedAct.actId, props.idid, props.id, selectedAct.editType);
    }
    setOpenDialog(false);
  };

  // 溶け込み精査
  // const [openScrutinyDialog, setOpenScrutinyDialog] = React.useState<boolean>(false);
  // const handleScrutinyOpenDialog = (editingId, actId, editType) => {
  //   setSelectedAct({ actId: actId, editingId: editingId, editType: editType });
  //   setOpenScrutinyDialog(true);
  // };
  // const handleScrutinyCloseDialog = () => {
  //   setOpenScrutinyDialog(false);
  // };
  // const handleScrutiny = () => {
  //   if (selectedAct) {
  //     props.setLoading(true);
  //     props.scrutiny(props.accountid, selectedAct.editingId, selectedAct.actId, props.idid, props.id, selectedAct.editType);
  //   }
  //   setOpenScrutinyDialog(false);
  // };

  // 精査結果取得
  const [scrutinyResult, setScrutinyResult] = React.useState<ScrutinyResult[]>([]);
  const [openScrutinyResultDialog, setOpenScrutinyResultDialog] = React.useState<boolean>(false);
  const handleScrutinyResultOpenDialog = () => {
    setOpenScrutinyResultDialog(true);
  };
  const handleScrutinyResultCloseDialog = () => {
    setOpenScrutinyResultDialog(false);
  };

  const handleGetScrutiny = async (editingId, actId) => {
    props.setLoading(true);
    try {
      const myInit = {
        headers: {}
      }
      const url = `/acts/${actId}/editings/${editingId}/enforce/scrutiny?accountid=${props.accountid}&id=${props.id}&state=done`;
      const res: ScrutinyResult[] = await API.get(API_NAME, url, myInit);
      setScrutinyResult(res);
      handleScrutinyResultOpenDialog();
    } catch (e) {
      console.log(e);
    }
    props.setLoading(false);
  };

  // 編集状態（否認）
  const handleEditStateDialog = (editingId: string, actId: string, existEditState: string, editType: string) => {
    setSelectedAct({ actId: actId, editingId: editingId, editType: editType });
    setSubDialog(true);
  };

  const [subDialog, setSubDialog] = React.useState(false);
  const openSubDialog = Boolean(subDialog);
  const closeSubDialog = () => setSubDialog(false);
  const okSubDialog = () => {
    if (selectedAct) {
      if (selectedAct.editType === '80' || selectedAct.editType === '81') {
        // 履歴操作の場合は状態変更しない
        const apiParam: EditStateAPIParam = {
          actId: selectedAct.actId,
          editingId: selectedAct.editingId,
          editType: selectedAct.editType,
          accountId: props.accountid,
          idid: props.idid,
          id: props.id,
          editState: '-1'
        }
        props.setLoading(true);
        props.putEditStateAsync(apiParam, false);
      } else {
        const apiParam: EditStateAPIParam = {
          actId: selectedAct.actId,
          editingId: selectedAct.editingId,
          editType: selectedAct.editType,
          accountId: props.accountid,
          idid: props.idid,
          id: props.id,
          editState: '10'
        }
        props.setLoading(true);
        props.putEditStateAsync(apiParam, true);
      }
    }
    closeSubDialog();
  };

  const closeSubDialog2 = () => {
    // 排他エラーダイアログを閉じる。フラグを下げて画面更新
    props.setApprovalErr(false);
    props.setLoading(true);
    props.getApprovalActs(props.accountid, props.id);
  };

  // const scrutinyDialog = () => {
  //   return (
  //     <Dialog
  //       open={openScrutinyDialog}
  //       keepMounted
  //       onClose={handleScrutinyCloseDialog}
  //       aria-labelledby="alert-dialog-slide-title"
  //       aria-describedby="alert-dialog-slide-description"
  //     >
  //       <DialogTitle id="alert-dialog-slide-title">{"溶け込み精査"}</DialogTitle>
  //       <DialogContent>
  //         <DialogContentText id="alert-dialog-slide-description">
  //           <Typography variant="body1" component="span">溶け込み精査を行いますか？</Typography>
  //         </DialogContentText>
  //       </DialogContent>
  //       <DialogActions>
  //         <Button onClick={handleScrutiny} color="primary">
  //           OK
  //         </Button>
  //         <Button onClick={handleScrutinyCloseDialog} color="primary">
  //           キャンセル
  //         </Button>
  //       </DialogActions>
  //     </Dialog>
  //   );
  // };

  const approvalDialog = () => {
    return (
      <Dialog
        open={openDialog}
        keepMounted
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"承認"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            承認を行いますか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleApprovalOk} color="primary">
            OK
          </Button>
          <Button onClick={handleCloseDialog} color="primary">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  // 施行日入力
  const [anchorElEnforceDateDialog, setAnchorElEnforceDateDialog] = React.useState(false);
  const openAnchorElEnforceDateDialog = Boolean(anchorElEnforceDateDialog);
  const handleCloseAnchorElEnforceDateDialog = () => setAnchorElEnforceDateDialog(false);
  const [stateEnforceInfo, setStateEnforceInfo] = React.useState<EnforceInfo>({ changeGroups: [], enforceGroups: [], editType: 0 });
  const handleAnchorElEnforceDateDialog = () => {
    setAnchorElEnforceDateDialog(false);
    if (selectedAct && stateEnforceInfo) {
      const apiParam: EditStateAPIParam = {
        actId: selectedAct.actId,
        editingId: selectedAct.editingId,
        editType: selectedAct.editType,
        accountId: props.accountid,
        idid: props.idid,
        id: props.id,
        editState: '-1'
      }
      props.setLoading(true);
      props.commitEnforceInfoAsync(apiParam, stateEnforceInfo);
    } else {
      console.error('selectedAct null');
    }
  };
  const viewEnforceDate = (editingId: string, actId: string, editActInfo: EditActInfo, actInfo: ActInfo, enforceInfo: EnforceInfo, editType: string) => {
    setSelectedAct({ actId: actId, editingId: editingId, editType: editType });
    setStateEditActInfo(Object.assign({}, editActInfo));
    setStateActInfo(Object.assign({}, actInfo));
    setStateEnforceInfo(enforceInfo);
    setAnchorElEnforceDateDialog(true);
  };

  // 改正例規情報
  const [anchorElEditActInfoDialog, setAnchorElEditActInfoDialog] = React.useState(false);
  const openAnchorElEditActInfoDialog = Boolean(anchorElEditActInfoDialog);
  const handleCloseAnchorElEditActInfoDialog = () => setAnchorElEditActInfoDialog(false);
  const [stateEditActInfo, setStateEditActInfo] = React.useState<EditActInfo>({
    publicationDate: '',
    editActType: '',
    editActNum: '',
    editActNumOptionViewFlag: false
  });
  const handleAnchorElEditActInfoDialog = () => {
    setAnchorElEditActInfoDialog(false);
    if (selectedAct && stateEnforceInfo) {
      const apiParam: EditStateAPIParam = {
        actId: selectedAct.actId,
        editingId: selectedAct.editingId,
        editType: selectedAct.editType,
        accountId: props.accountid,
        idid: props.idid,
        id: props.id,
        editState: '-1'
      }
      props.setLoading(true);
      props.commitEditActInfoAsync(apiParam, stateEditActInfo);
    } else {
      console.error('selectedAct null');
    }
  };
  const viewEditActInfo = (editingId: string, actId: string, editActInfo: EditActInfo, actInfo: ActInfo, editType: string) => {
    setSelectedAct({ actId: actId, editingId: editingId, editType: editType });
    setStateEditActInfo(Object.assign({}, editActInfo));
    setStateActInfo(Object.assign({}, actInfo));
    setAnchorElEditActInfoDialog(true);
  };

  // 例規情報
  const [anchorElActInfoDialog, setAnchorElActInfoDialog] = React.useState(false);
  const openAnchorElActInfoDialog = Boolean(anchorElActInfoDialog);
  const handleCloseAnchorElActInfoDialog = () => setAnchorElActInfoDialog(false);
  const [stateActInfo, setStateActInfo] = React.useState<ActInfo>({
    publicationDate: '',
    actType: '',
    actNum: '',
    actNumOptionViewFlag: false,
    expireDate: '',
    beforeEnactment: '',
    afterEnactment: '',
    preamble: '',
    enforceDate: '',
  });
  const handleAnchorElActInfoDialog = () => {
    setAnchorElActInfoDialog(false);
    if (selectedAct && stateEnforceInfo) {
      const apiParam: EditStateAPIParam = {
        actId: selectedAct.actId,
        editingId: selectedAct.editingId,
        editType: selectedAct.editType,
        accountId: props.accountid,
        idid: props.idid,
        id: props.id,
        editState: '-1'
      }
      props.setLoading(true);
      props.commitActInfoAsync(apiParam, stateActInfo);
    } else {
      console.error('selectedAct null');
    }
  };
  const viewActInfo = (editingId: string, actId: string, actInfo: ActInfo, editType: string) => {
    setSelectedAct({ actId: actId, editingId: editingId, editType: editType });
    setStateActInfo(Object.assign({}, actInfo));
    setAnchorElActInfoDialog(true);
  };

  // 資料作成
  const [stateEditType, setStateEditType] = React.useState<string>('0');
  const [stepId, setStepId] = React.useState<number>(1);
  const [stateStepIdInfo, setStateStepIdInfo] = React.useState<number[]>([1]);
  const handleSetStepId = (stepId: number) => {
    setStepId(stepId);
  }
  const [anchorElDocumentCreateDialog, setAnchorElDocumentCreateDialog] = React.useState(false);
  const openAnchorElDocumentCreateDialog = Boolean(anchorElDocumentCreateDialog);
  const handleCloseAnchorElDocumentCreateDialog = () => {
    setAnchorElDocumentCreateDialog(false);
    setDownloadDocument(initialDownloadDocument());
  }
  const [downloadDocument, setDownloadDocument] = React.useState<DownloadDocument>(initialDownloadDocument());
  const handleAnchorElDocumentCreateDialog = async (type: number,) => {
    setAnchorElDocumentCreateDialog(false);
    props.setLoading(true);
    const myInit = {
      headers: {},
    };
    if (selectedAct) {
      const url = `/acts/${selectedAct.actId}/editings/${selectedAct.editingId}/steps/${stepId}/document?idid=${props.idid}&accountid=${props.accountid}&documentType=${type}`;
      try {
        const res = await API.get(API_NAME, url, myInit);
        const tmp: string = res.outPath;
        const newOutPath = tmp.replace(/private\/.*?\//, '');
        switch (type) {
          case 0:
            downloadDocument.allact = newOutPath;
            break;
          case 1:
            downloadDocument.revision = newOutPath;
            break;
          case 2:
            downloadDocument.newold = newOutPath;
            break;
          case 7:
            downloadDocument.publication = newOutPath;
            break;
          default:
            break;
        }
      } catch (e) {
        // エラー
        console.log(e);
      }
    } else {
      console.error('selectedAct null');
    }
    setAnchorElDocumentCreateDialog(true);
    props.setLoading(false);
  };
  const viewDocumentCreate = (editingId: string, actId: string, stepId: string, editType: string, stepIdInfo: number[]) => {
    setStateEditType(editType);
    setStateStepIdInfo(stepIdInfo);
    setStepId(1);
    setSelectedAct({ actId: actId, editingId: editingId, editType: editType });
    setAnchorElDocumentCreateDialog(true);
  };

  const getTitle = (act: ApprovalAct) => {
    if (act.newTitle) {
      return (
        <>
          {'(' + act.editingId + ')' + act.newTitle + '[旧:' + act.title + ']'}
        </>
      )
    } else {
      return (
        <>
          {'(' + act.editingId + ')' + act.title}
        </>
      )
    }
  };

  const createActInfo = (a: ApprovalAct) => {
    if (a.editType === '0' || a.editType === '2') {
      return (
        <Tooltip title="制定情報を設定する">
          <IconButton sx={{
            margin: 0.2,
            color: (theme) => theme.palette.secondary.main,
          }} edge="end" aria-label="actInfo" onClick={() => viewActInfo(a.editingId, a.actId, a.actInfo, a.editType)}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title="改正情報を設定する">
          <IconButton sx={{
            margin: 0.2,
            color: (theme) => theme.palette.secondary.main,
          }} edge="end" aria-label="editActInfo" onClick={() => viewEditActInfo(a.editingId, a.actId, a.editActInfo, a.actInfo, a.editType)}>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )
    }
  };
  const createOpeButton = (a: ApprovalAct) => {
    return (a.editType !== '80' && a.editType !== '81') && a.editState === '0' ? (
      <>
        {createActInfo(a)}
        <Tooltip title="資料を出力する">
          <IconButton sx={{
            margin: 0.2,
            color: (theme) => theme.palette.secondary.main,
          }} edge="end" aria-label="fileDownload" onClick={() => viewDocumentCreate(a.editingId, a.actId, a.stepId, a.editType, a.stepIdInfo)}>
            <OutputIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="施行日を設定する">
          <IconButton sx={{
            margin: 0.2,
            color: (theme) => theme.palette.secondary.main,
          }} edge="end" aria-label="enforceDate" onClick={() => viewEnforceDate(a.editingId, a.actId, a.editActInfo, a.actInfo, a.enforceInfo, a.editType)}>
            <CalendarTodayIcon />
          </IconButton>
        </Tooltip>
        <br />
      </>
    ) : '';
  };

  const getStateText = (param: string) => {
    const r = props.editStateList.filter((v) => v.id === param)
    return r.length === 1 ? r[0].name : '？？？？';
  };

  const createActionButton = (a: ApprovalAct, ok: boolean) => {
    // a.editState === '2' とotherは現状呼び出しされない
    // 精査後即承認となり、溶け込み精査と承認が一元化されたため
    const r = a.editState === '3' ? (<Button sx={{
      color: (theme) => theme.palette.secondary.main,
    }} size="small" disabled={!ok} onClick={() => handleOpenDialog(a.editingId, a.actId, a.editType)}>承認</Button>)
      : a.editState === '2' ? (<Button sx={{
        color: (theme) => theme.palette.secondary.main,
      }} size="small" disabled={true}>溶け込み精査中</Button>)
        : a.editState === '5' ? (<Button sx={{
          color: (theme) => theme.palette.secondary.main,
        }} size="small" disabled={true}>溶け込み中</Button>)
          : a.editState === '0' ? (<Button sx={{
            color: (theme) => theme.palette.secondary.main,
          }} size="small" disabled={!ok} onClick={() => handleOpenDialog(a.editingId, a.actId, a.editType)}>承認</Button>)
            : (<Button sx={{
              color: (theme) => theme.palette.secondary.main,
            }} size="small" disabled={true}>承認</Button>);
    return r;
  };
  const createDisapproval = (a: ApprovalAct) => {
    return (a.editState === '0' || a.editState === '3' || a.editState === '4') ? (
      <Button sx={{
        color: (theme) => theme.palette.secondary.main,
      }} size="small" onClick={() => handleEditStateDialog(a.editingId, a.actId, a.editState, a.editType)} >否認</Button>
    ) : (
      <Button sx={{
        color: (theme) => theme.palette.secondary.main,
      }} size="small" disabled={true}>否認</Button>
    );
  }
  const createGetScrutiny = (a: ApprovalAct) => {
    const r = a.editState === '4' ? (<Button sx={{
      color: (theme) => theme.palette.secondary.main,
    }} size="small" onClick={() => handleGetScrutiny(a.editingId, a.actId)} >精査結果</Button>) : '';
    return r;
  };

  const okApproval = (a: ApprovalAct) => {
    const f = (cg: ChangeGroup[], eg: EnforceGroup[]) => {
      const m = cg.map((c) => {
        if (c.enforceGroupId === '-1') {
          return true;
        }
        const finde = eg.find((e) => e.enforceGroupId === c.enforceGroupId);
        // 施行日の設定されていない改正情報を探す
        return !(finde && finde.enforceDate);
      });
      // 施行日の設定されていない改正情報があればNG
      return m.find((item) => item === true) === true;
    };
    if (a.editType === '0' || a.editType === '2') {
      return true === (a.actInfo.publicationDate && a.actInfo.actNum
        && !f(a.enforceInfo.changeGroups, a.enforceInfo.enforceGroups));
    } else if (a.editType === '1' || a.editType === '3') {
      return true === (a.editActInfo.publicationDate && a.editActInfo.editActNum
        && !f(a.enforceInfo.changeGroups, a.enforceInfo.enforceGroups));
    }
    return true;
  };
  const createWarn = (ok: boolean) => {
    return ok ? '' : (
      <>
        <br />
        <WarningIcon sx={{
          color: "#ffb74d",
        }} />
        <Typography variant="subtitle1" component="span">公開日(公布日)、番号、施行日の設定を確認してください。</Typography>
      </>
    );
  };

  const createActList = (b: ApprovalActs) => {
    return b.approvalActTables.map((a, index) => {
      const ok = okApproval(a);
      return (
        <div key={index}>
          <ListItem button onClick={() => editingView(a.editingId, a.actId, a.stepId, a.editType)}>
            <ListItemIcon sx={{
              color: (theme) => theme.palette.primary.main,
            }}>
              <NoteIcon />
            </ListItemIcon>
            <ListItemText primary={getTitle(a)} secondary={
              <>
                <Typography variant="subtitle1" component="span">番号：</Typography>
                <Typography variant="body1" component="span">{a.actNum}</Typography>
                <br />
                {a.editType === '1' || a.editType === '3' ? (
                  <>
                    <Typography variant="subtitle1" component="span">改正番号：</Typography>
                    <Typography variant="body1" component="span">{a.editActNum}</Typography>
                    <br />
                  </>
                ) : ''}
                <Typography variant="subtitle1" component="span">編集種別：</Typography>
                <Typography variant="body1" component="span">{s_EDIT_TYPE[a.editType]}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">状態：</Typography>
                <Typography variant="body1" component="span">{getStateText(a.editState)}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">更新日時：</Typography>
                <Typography variant="body1" component="span">{a.updateDate}</Typography>
                {createWarn(ok)}
              </>
            } />
            <ListItemSecondaryAction>
              {createOpeButton(a)}
              {createActionButton(a, ok)}
              <br />
              {createDisapproval(a)}
              <br />
              {createGetScrutiny(a)}
            </ListItemSecondaryAction>
          </ListItem>
          <Divider />
        </div>
      );
    });
  }

  const [opens, setOpens] = React.useState(Array(props.approvalActs.length).fill(true));
  const handleClick = (index: number) => {
    const copy = opens.slice();
    copy[index] = !copy[index];
    setOpens(copy);
  };
  const createList = () => {
    if (props.approvalActs.length < 1) {
      return (
        <ListItem key={'item0'}>
          <ListItemText primary={'承認待ちのデータはありません。'} />
        </ListItem>);
    }
    return props.approvalActs.map((b, index) => {
      return (
        <div key={index}>
          <ListItem key={'li' + index}>
            {opens[index] ? <ExpandLess onClick={() => handleClick(index)} /> : <ExpandMore onClick={() => handleClick(index)} />}
            <ListItemText key={b.id} primary={b.id + 'さんの承認待ちデータ'} onClick={() => handleClick(index)} />
          </ListItem>
          <Collapse in={opens[index]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {createActList(b)}
            </List>
          </Collapse>
          <Divider />
        </div>
      )
    });
  }

  const reload = () => {
    props.setLoading(true);
    props.getApprovalActs(props.accountid, props.id);
  };

  return (
    <>
      {approvalDialog()}
      {/* {scrutinyDialog()} */}
      <Typography sx={{ margin: 2 }} variant="h5" gutterBottom>
        <ApprovalIcon fontSize='large' color='primary' sx={{ marginBottom: 1, marginRight: 1, verticalAlign: 'middle' }} />
        編集内容を承認する
        <Tooltip title="再読み込み">
          <IconButton sx={{
            margin: 0.2,
            color: (theme) => theme.palette.secondary.main,
          }} edge="end" aria-label="enforceDate" size='large' onClick={reload}>
            <ReplayIcon />
          </IconButton>
        </Tooltip>
      </Typography>
      <Paper sx={{
        minWidth: 275,
        margin: 1,
        padding: 1,
        backgroundColor: (theme) => theme.palette.primary[50],
      }} elevation={3}>
        <List component="nav" aria-label="My editing acts">
          {createList()}
        </List>
      </Paper>
      <EnforceDateDialog
        open={openAnchorElEnforceDateDialog}
        enforceInfo={stateEnforceInfo}
        editType={selectedAct?.editType ? selectedAct?.editType : ''}
        editActInfo={stateEditActInfo}
        actInfo={stateActInfo}
        setStateEnforceInfo={setStateEnforceInfo}
        handleClose={handleCloseAnchorElEnforceDateDialog}
        handleOk={handleAnchorElEnforceDateDialog}
      />
      <EditActInfoDialog
        open={openAnchorElEditActInfoDialog}
        actTypes={props.actTypes}
        editActInfo={stateEditActInfo}
        startDate={props.startDate}
        actInfo={stateActInfo}
        setStateEditActInfo={setStateEditActInfo}
        handleClose={handleCloseAnchorElEditActInfoDialog}
        handleOk={handleAnchorElEditActInfoDialog}
      />
      <ActInfoDialog
        open={openAnchorElActInfoDialog}
        actTypes={props.actTypes}
        actInfo={stateActInfo}
        startDate={props.startDate}
        setStateActInfo={setStateActInfo}
        handleClose={handleCloseAnchorElActInfoDialog}
        handleOk={handleAnchorElActInfoDialog}
      />
      <ScrutinyResultDialog
        open={openScrutinyResultDialog}
        scrutinyResult={scrutinyResult}
        handleClose={handleScrutinyResultCloseDialog}
      />
      <DocumentCreateDialog
        open={openAnchorElDocumentCreateDialog}
        downloadDocument={downloadDocument}
        editType={stateEditType}
        stepId={stepId}
        stepIdInfo={stateStepIdInfo}
        handleClose={handleCloseAnchorElDocumentCreateDialog}
        handleCreate={handleAnchorElDocumentCreateDialog}
        handleSetStepId={handleSetStepId}
      />
      <Dialog
        open={openSubDialog}
        keepMounted
        onClose={closeSubDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">否認</DialogTitle>
        <DialogContent>
          <Typography>編集内容を否認し、編集者に差し戻します。よろしいですか？</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={okSubDialog} color="primary">
            OK
          </Button>
          <Button onClick={closeSubDialog} color="primary">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={props.approvalError}
        keepMounted
        onClose={closeSubDialog2}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">排他エラー</DialogTitle>
        <DialogContent>
          <Typography>処理を中断しました。しばらくたってからもう一度実行してください。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSubDialog2} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
