import React from 'react';

import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import Divider from '@mui/material/Divider';
import Newspaper from '@mui/icons-material/Newspaper';

import { AppState } from '../states/appState';
import { WhatsnewState, WhatsnewAct } from '../states/whatsnewState';

type WhatsnewActProps = AppState & WhatsnewState & WhatsnewAct;

const EDIT_TYPE = [
  '新規制定',
  '一部改正',
  '全部改正',
  '廃止',
];

const editingView = (editingId: string, actId: string, stepId: string) => {
  const query = `?type=${'editor'}&actId=${actId}&editingId=${editingId}&stepId=${stepId}&miekesi=true`;
  const url = process.env.REACT_APP_VIEWER_URL ? process.env.REACT_APP_VIEWER_URL.replace('{hostname}', window.location.hostname) : process.env.REACT_APP_VIEWER_URL;
  window.open(url + query, `viewer_${actId}_${editingId}_${stepId}`);
};

export const Whatsnew: React.FC<WhatsnewActProps> = (props) => {

  const createList = () => {
    return props.whatsnews.map((i, index) => {
      return (
        <div key={"" + index}>
          <ListItem button onClick={() => editingView(i.editingId, i.actId, i.stepIds[0])}>
            <ListItemIcon sx={{
              color: (theme) => theme.palette.primary.main,
            }}>
              <FiberNewIcon />
            </ListItemIcon>
            <ListItemText primary={i.title} secondary={
              <>
                <Typography variant="subtitle1" component="span">番号：</Typography>
                <Typography variant="body1" component="span">{i.actNum}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">改正番号：</Typography>
                <Typography variant="body1" component="span">{i.editActNum}</Typography>
                <br />
                <Typography variant="subtitle1" component="span">編集種別：</Typography>
                <Typography variant="body1" component="span">{EDIT_TYPE[Number.parseInt(i.editType)]}</Typography>
              </>
            } />
          </ListItem>
          <Divider />
        </div>
      )
    });
  };

  return (
    <>
      <Typography sx={{ margin: 2 }} variant="h5" gutterBottom>
        <Newspaper fontSize='large' color='primary' sx={{ marginBottom: 1, marginRight: 1, verticalAlign: 'middle' }} />
        最近更新された文書を確認する
      </Typography>
      <Paper sx={{
        minWidth: 275,
        margin: 2,
        padding: 1,
        backgroundColor: (theme) => theme.palette.primary[50],
      }} elevation={3}>
        <List component="nav" aria-label="My editing acts">
          {createList()}
        </List>
      </Paper>
    </>
  );
};
