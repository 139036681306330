import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Checkbox } from '@mui/material';
import Typography from '@mui/material/Typography';

import { ActType, EditActInfo, ActInfo } from '../states/approvalState';

type Props = {
  open: boolean;
  actTypes: ActType[];
  editActInfo: EditActInfo;
  startDate: string;
  actInfo: ActInfo;
  setStateEditActInfo: (i: EditActInfo) => void;
  handleClose: () => void;
  handleOk: () => void;
};

export const EditActInfoDialog: React.FC<Props> = (props) => {

  const handlePublicDate = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newInfo = Object.assign({}, props.editActInfo);
    newInfo.publicationDate = (event.target as HTMLInputElement).value;
    props.setStateEditActInfo(newInfo);
  };
  const handleEditActType = (event: SelectChangeEvent) => {
    const newInfo = Object.assign({}, props.editActInfo);
    newInfo.editActType = (event.target as HTMLInputElement).value;
    props.setStateEditActInfo(newInfo);
  };
  const handleEditActNum = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newInfo = Object.assign({}, props.editActInfo);
    newInfo.editActNum = (event.target as HTMLInputElement).value;
    props.setStateEditActInfo(newInfo);
  };
  const handleEditActEdaNum = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newInfo = Object.assign({}, props.editActInfo);
    newInfo.editActEdaNum = (event.target as HTMLInputElement).value;
    props.setStateEditActInfo(newInfo);
  };
  const handleEditActMagoNum = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newInfo = Object.assign({}, props.editActInfo);
    newInfo.editActMagoNum = (event.target as HTMLInputElement).value;
    props.setStateEditActInfo(newInfo);
  };
  const handleEditActNumOption = (event) => {
    const newInfo = Object.assign({}, props.editActInfo);
    newInfo.editActNumOptionViewFlag = event.target.checked;
    props.setStateEditActInfo(newInfo);
  };
  const handleEditActNumOptionTextField = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newInfo = Object.assign({}, props.editActInfo);
    newInfo.editActNumOption = (event.target as HTMLInputElement).value;
    props.setStateEditActInfo(newInfo);
  };

  const [subDialog, setSubDialog] = React.useState(false);
  const openSubDialog = Boolean(subDialog);
  const closeSubDialog = () => setSubDialog(false);

  const viewActNum = () => {
    return (
      <>
        <TextField
          id="standard-number"
          label="改正番号"
          type="number"
          value={props.editActInfo.editActNum || ''}
          onChange={handleEditActNum}
          sx={{
            margin: (theme) => theme.spacing(1),
            width: 100,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        {props.editActInfo.editActNum ? (
          <TextField
            id="standard-number"
            label="枝番号"
            type="number"
            value={props.editActInfo.editActEdaNum || ''}
            onChange={handleEditActEdaNum}
            sx={{
              margin: (theme) => theme.spacing(1),
              width: 100,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ) : (
          <TextField
            disabled
            id="standard-number"
            label="枝番号"
            type="number"
            value=""
            sx={{
              margin: (theme) => theme.spacing(1),
              width: 100,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {props.editActInfo.editActNum && props.editActInfo.editActEdaNum ? (
          <TextField
            id="standard-number"
            label="孫番号"
            type="number"
            value={props.editActInfo.editActMagoNum || ''}
            onChange={handleEditActMagoNum}
            sx={{
              margin: (theme) => theme.spacing(1),
              width: 100,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ) : (
          <TextField
            disabled
            id="standard-number"
            label="孫番号"
            type="number"
            value=""
            sx={{
              margin: (theme) => theme.spacing(1),
              width: 100,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      </>
    );
  };

  const viewActNumOption = () => {
    return (
      <>
        <InputLabel id="demo-simple-select-label" sx={{
            margin: (theme) => theme.spacing(1),
            width: 70,
        }} >任意表示</InputLabel>
        <Checkbox
          checked={props.editActInfo.editActNumOptionViewFlag}
          onChange={handleEditActNumOption}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        {props.editActInfo.editActNumOptionViewFlag ? (
          <TextField
            id="ActNumOption-TextField"
            // label="ActNumOption"
            type="string"
            value={props.editActInfo.editActNumOption || ''}
            onChange={handleEditActNumOptionTextField}
            sx={{
              margin: (theme) => theme.spacing(1),
              width: 200,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        ) : (<TextField
          disabled
          id="ActNumOption-TextField"
          // label="ActNumOption"
          type="string"
          value={props.editActInfo.editActNumOption || ''}
          onChange={handleEditActNumOptionTextField}
          sx={{
            margin: (theme) => theme.spacing(1),
            width: 200,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />)}
      </>
    );
  };

  const handlePreOk = () => {
    if (props.editActInfo.publicationDate) {
      const sStartDate = new Date(props.startDate);
      const sOPDate = new Date(props.actInfo.publicationDate);
      const sOEDate = new Date(props.actInfo.enforceDate);
      const sPDate = new Date(props.editActInfo.publicationDate);
      if (sStartDate.getTime() <= sPDate.getTime()
        && sOPDate.getTime() <= sPDate.getTime()
        && sOEDate.getTime() <= sPDate.getTime()) {
        props.handleOk();
      } else {
        setSubDialog(true);
      }
    } else {
      props.handleOk();
    }
  }

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"改正情報設定"}</DialogTitle>
      <DialogContent>
        <TextField
          id="date"
          label="公開日(公布日)"
          type="date"
          value={props.editActInfo.publicationDate}
          onChange={handlePublicDate}
          sx={{
            margin: (theme) => theme.spacing(1),
            width: 200,
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        <FormControl sx={{
          margin: (theme) => theme.spacing(1),
          minWidth: 120,
        }}>
          <InputLabel id="demo-simple-select-label">改正種別</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            label="改正種別"
            value={props.editActInfo.editActType}
            onChange={handleEditActType}
          >
            {props.actTypes.map((type) => {
              return (<MenuItem key={type.id} value={type.id}>{type.name}</MenuItem>);
            })}
          </Select>
        </FormControl>
        <br />
        {viewActNum()}
        <br />
        {viewActNumOption()}
      </DialogContent>
      <DialogActions>
        <Button onClick={handlePreOk} color="primary">
          OK
        </Button>
        <Button onClick={props.handleClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
      <Dialog
        open={openSubDialog}
        keepMounted
        onClose={closeSubDialog}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">公開日(公布日)が正しくありません。</DialogTitle>
        <DialogContent>
          <Typography>公開日(公布日)はシステム利用開始日（{props.startDate}）以降で入力してください。</Typography>
          <Typography>また公開日(公布日)は制定公開日(公布日)以降かつ制定施行日以降で入力してください。</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSubDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};
