import { connect } from 'react-redux';
import { Action } from 'typescript-fsa';

import { searchActions } from '../actions/searchAction';
import { Search } from '../components/Search';
import { appActions } from '../actions/appAction';
import { Conditions, EditingGroup } from '../states/searchState';

export interface SearchActions {
    search: (v: Conditions) => Action<Conditions>;
    searchAsync: (c: Conditions, accountid: string, id: string) => void;
    searchEstablishAsync: (c: Conditions, accountid: string, id: string) => void;
    setUndiscloseAsync: (actId: string, undisclose: boolean, c: Conditions, accountid: string, id: string) => void;
    getEnforcegroupsAsync: (actid: string, accountid: string) => void;
    getEnforcegroups: (any) => void;
    clearEnforcegroups: (any) => void;
    postEnforcegroups: (accountid: string, id: string, idid: string, actId: string, editType: string, editingGroups: EditingGroup[]) => void;
    postEnforceCancelAsync: (accountid: string, id: string, idid: string, actId: string, editingIds: string[]) => void;
    postExpireDateAsync: (accountid: string, id: string, idid: string, actId: string, expireDate: string) => void;
    setLoading: (loading: boolean) => void;
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        search: (v: Conditions) => dispatch(searchActions.search(v)),
        searchAsync: (c: Conditions, accountid: string, id: string) => dispatch(searchActions.searchAsync(c, accountid, id)),
        searchEstablishAsync: (c: Conditions, accountid: string, id: string) => dispatch(searchActions.searchEstablishAsync(c, accountid, id)),
        setUndiscloseAsync: (actId: string, undisclose: boolean, c: Conditions, accountid: string, id: string) =>
            dispatch(searchActions.setUndiscloseAsync(actId, undisclose, c, accountid, id)),
        getEnforcegroupsAsync: (actid: string, accountid: string) => dispatch(searchActions.getEnforcegroupsAsync(actid, accountid)),
        getEnforcegroups: (any) => dispatch(searchActions.getEnforcegroups(any)),
        clearEnforcegroups: (any) => dispatch(searchActions.clearEnforcegroups(any)),
        postEnforcegroups: (accountid: string, id: string, idid: string, actId: string, editType: string, editingGroups: EditingGroup[]) =>
            dispatch(searchActions.postEnforcegroupsAsync(accountid, id, idid, actId, editType, editingGroups)),
        postEnforceCancelAsync: (accountid: string, id: string, idid: string, actId: string, editingIds: string[]) =>
            dispatch(searchActions.postEnforceCancelAsync(accountid, id, idid, actId, editingIds)),
        postExpireDateAsync: (accountid: string, id: string, idid: string, actId: string, expireDate: string) =>
            dispatch(searchActions.postExpireDateAsync(accountid, id, idid, actId, expireDate)),
        setLoading: (loading: boolean) => dispatch(appActions.setLoading(loading)),
    };
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign({}, state.searchingState, state.appState);
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);
