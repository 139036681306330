import { connect } from 'react-redux';

import { appActions } from '../actions/appAction';
import { informationActions } from '../actions/informationAction';
import { InformationItem } from '../states/informationState';

import { Information } from '../components/Information';

export interface InformationActions {
    getInformations: (accountId: string, id: string) => void;
    postInformation: (item: InformationItem) => void;
    deleteInformation: (informationId: string, account: string, accountId: string, id: string) => void;
    setLoading: (loading: boolean) => void;
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        getInformations: (accountId: string, id: string) => dispatch(informationActions.getInformationsAsync(accountId, id)),
        postInformation: (item: InformationItem) => dispatch(informationActions.postInformationAsync(item)),
        deleteInformation: (informationId: string, account: string, accountId: string, id: string) => dispatch(informationActions.deleteInformationAsync(informationId, account, accountId, id)),
        setLoading: (loading: boolean) => dispatch(appActions.setLoading(loading)),
    };
};

const mapStateToProps = (state, ownProps) => {
    return Object.assign({}, state.informationsState, state.appState);
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);
