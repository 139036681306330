import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { Storage } from 'aws-amplify';

import { DownloadDocument } from './Approval';

type Props = {
  open: boolean;
  downloadDocument: DownloadDocument;
  editType: string;
  stepId: number;
  stepIdInfo: number[];
  handleClose: () => void;
  handleCreate: (type: number,) => void;
  handleSetStepId: (stepId: number) => void;
};

export const DocumentCreateDialog: React.FC<Props> = (props) => {

  const handleClick = (type: number) => {
    props.handleCreate(type);
  };

  const handleSelectStepId = (event: SelectChangeEvent) => {
    const newStepId = (parseInt((event.target as HTMLInputElement).value));
    props.handleSetStepId(newStepId);
  };

  const downloadContents = (title: string, path: string) => {
    return (
      <>
        <span style={{ marginLeft: '10px' }}>
          {title}
          <Tooltip title="ダウンロード">
            <IconButton aria-label="remove" color='secondary' onClick={() => downloadForm(path)}>
              <AttachFileIcon color='secondary' fontSize="medium" />
            </IconButton>
          </Tooltip>
        </span>
        <br />
      </>
    );
  };
  const downloadForm = async (path: string) => {
    const file = await Storage.get(path, { level: 'private' });
    const xhr = new XMLHttpRequest();
    xhr.open('GET', '' + file);
    xhr.responseType = 'blob';
    xhr.onloadend = function () {
      if (xhr.status !== 200) {
        return false;
      }
      // TODO ダウンロード処理はブラウザ依存している？
      //const blob = new Blob([xhr.response], { "type" : "text/plain" });
      //const blob = new Blob([xhr.response], { "type" : "application/msword" });
      //const blob = new Blob([xhr.response], { "type" : "image/jpeg" });
      const blob = new Blob([xhr.response]);
      const link = document.createElement('a');
      link.download = path;
      link.href = window.URL.createObjectURL(blob);
      link.click();
      //window.navigator.msSaveOrOpenBlob(xhr.response, fileName);
    };
    xhr.send();
  };

  const createButtons = () => {
    const ret: Array<JSX.Element> = [];
    ret.push((
      <Button key={'1'} variant="outlined" color="secondary" onClick={() => handleClick(0)} sx={{
        margin: (theme) => theme.spacing(1),
      }}>全文</Button>
    ));
    ret.push((
      <Button key={'7'} variant="outlined" color="secondary" onClick={() => handleClick(7)} sx={{
        margin: (theme) => theme.spacing(1),
      }}>公布文</Button>
    ));
    if (props.editType === '1' || props.editType === '3') {
      ret.push((
        <Button key={'2'} variant="outlined" color="secondary" onClick={() => handleClick(1)} sx={{
          margin: (theme) => theme.spacing(1),
        }}>改正文</Button>
      ));
    }
    if (props.editType === '1') {
      ret.push((
        <Button key={'3'} variant="outlined" color="secondary" onClick={() => handleClick(2)} sx={{
          margin: (theme) => theme.spacing(1),
        }}>新旧対照表</Button>
      ));
    }
    return ret;
  };

  const createSteIdSelectBox = () => {
    if (props.stepIdInfo.length > 1) {
      return (
        <FormControl sx={{
          margin: (theme) => theme.spacing(1),
          minWidth: 120,
        }} >
          <InputLabel id="demo-simple-select-label">段</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            label="段"
            value={'' + props.stepId}
            onChange={handleSelectStepId}
          >
            {props.stepIdInfo.map((stepId) => {
              return (<MenuItem key={stepId} value={stepId}>{stepId}段目</MenuItem>);
            })}
          </Select>
        </FormControl>
      )
    } else {
      return (
        <FormControl sx={{
          margin: (theme) => theme.spacing(1),
          minWidth: 120,
        }} disabled>
          <InputLabel id="demo-simple-select-label">段</InputLabel>
          <Select
            labelId="simple-select-label"
            id="simple-select"
            label="段"
            value={'' + props.stepId}
            onChange={handleSelectStepId}
          >
            {props.stepIdInfo.map((stepId) => {
              return (<MenuItem key={stepId} value={stepId}>{stepId}段目</MenuItem>);
            })}
          </Select>
        </FormControl>
      )
    }
  };

  return (
    <Dialog
      open={props.open}
      keepMounted
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{"資料を出力する"}</DialogTitle>
      <DialogContent>
        {createSteIdSelectBox()}
        {createButtons()}
        <br />
        {props.downloadDocument.allact ? downloadContents('全文', props.downloadDocument.allact) : ''}
        {props.downloadDocument.publication ? downloadContents('公布文', props.downloadDocument.publication) : ''}
        {props.downloadDocument.revision ? downloadContents('改正文', props.downloadDocument.revision) : ''}
        {props.downloadDocument.newold ? downloadContents('新旧対照表', props.downloadDocument.newold) : ''}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color="primary">
          キャンセル
        </Button>
      </DialogActions>
    </Dialog>
  );
};
