import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { currentAppAction } from '../actions/currentAppAction';

export interface CurrentActTablesState {
    currentActTables: CurrentActState[];
}

export interface CurrentActState {
    actId: string;
    revision: string;
    revisionDate: string;
    informal: boolean;
    title: string;
    actNum: string;
    actState: string;
}

const initialState: CurrentActTablesState = {
    currentActTables: [{
        actId: '',
        revision: '',
        revisionDate: '',
        informal: false,
        title: '',
        actNum: '',
        actState: '',
    }]
};

export const currentAppReducer = reducerWithInitialState(initialState)
    .case(currentAppAction.getCurrentActTables, (state, payload) => {
        const result = payload.map(item => {
            return {
                actId: item.actId,
                revision: item.revision,
                revisionDate: item.revisionDate,
                title: item.title,
                informal: item.informal,
                actNum: item.actNum,
                actState: item.actState,
            };
        });
        return Object.assign({}, state, { currentActTables: result });
    });
