import actionCreatorFactory from 'typescript-fsa';
import { Dispatch, Action } from 'redux';
import { API } from 'aws-amplify';

import { appActions } from '../actions/appAction';
import { SearchResult, Conditions } from '../states/searchState';

const actionCreator = actionCreatorFactory();

const API_NAME = process.env.REACT_APP_API_1_NAME ? process.env.REACT_APP_API_1_NAME : 'lcrud';

export const repealActions = {
  searchRepealCondition: actionCreator<Conditions>('ACTIONS_SEARCH_REPEAL_CONDITION'),
  searchRepealResult: actionCreator<SearchResult>('ACTIONS_SEARCH_REPEAL_RESULT'),
  searchRepealAsync: (c: Conditions, accountid: string) => {
    return async (dispatch: Dispatch<Action>) => {
      try {
        const myInit1 = {
          headers: {}
        };
        const url1 = `/repeal?accountid=${accountid}&size=${c.rowsPerPage}&start=${c.page * c.rowsPerPage}&mode=${c.mode}`;
        const url2 = '' + (c.words ? `&q=${c.words}` : '') + (c.actnum ? `&actnum=${c.actnum}` : '') + (c.catalog ? `&catalog=${c.catalog}` : '')
            + (c.field ? `&field=${c.field}` : '') + (c.userGroupId ? `&group=${c.userGroupId}` : '');
        const res1 = await API.get(API_NAME, url1 + url2, myInit1);

        dispatch(repealActions.searchRepealCondition(c));
        dispatch(repealActions.searchRepealResult(res1));

      } catch (err) {
        console.log(err);
      }
      dispatch(appActions.setLoading(false));
    };
  },
};